import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { AccountCircle } from "@material-ui/icons";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores/store";
import Box from "@material-ui/core/Box/Box";
import MenuList from "@material-ui/core/MenuList";
import Button from "@material-ui/core/Button";
import { Theme } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { StyleProps } from "./style";

interface Props {
  handleDrawerToggle: () => void;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: ({ prefersDarkMode }) =>
      prefersDarkMode ? "#212121" : theme.palette.primary.light,
  },
  logo: {
    marginRight: theme.spacing(1),
    width: 50,
  },
  menuButton: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  userButton: {
    textTransform: "none",
  },
  title: {
    fontWeight: "bold",
  },
}));

export default observer(function Appbar({ handleDrawerToggle }: Props) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const classes = useStyles({ prefersDarkMode });
  const { userStore } = useStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    history.push("/profile");
    setAnchorEl(null);
  };

  const handleLogout = () => {
    userStore.logout();
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Box display="flex">
        <Box flexGrow={1}>
          <Toolbar>
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <img src="/kedjora.png" alt="logo" className={classes.logo} />
            <Typography variant="h6" className={classes.title}>
              Kedjora Boutique
            </Typography>
          </Toolbar>
        </Box>
        <Box>
          <Toolbar>
            <Button
              size="medium"
              onClick={handleMenu}
              variant="text"
              className={classes.userButton}
              startIcon={<AccountCircle />}
            >
              <Typography component="p">
                {userStore.user?.displayName}
              </Typography>
            </Button>
            <Menu
              elevation={3}
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <MenuList>
                <MenuItem alignItems="center" onClick={handleProfile}>
                  Manage Profile
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </MenuList>
            </Menu>
          </Toolbar>
        </Box>
      </Box>
    </AppBar>
  );
});
