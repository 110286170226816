import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
  Divider,
  Grid,
  CardActions,
  Collapse,
  IconButton,
} from "@material-ui/core";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStyles } from "../../app/layout/style";
import { SalesTrend } from "../../app/models/sale";
import moment from "moment";

interface Props {
  item: SalesTrend;
}

export default observer(function ReportSalesTrendCard({ item }: Props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar
            src={`${item.image ? item.image : "/no-image.png"}`}
            className={classes.large}
          ></Avatar>
        }
        title={item.itemCode}
        subheader={item.color}
      />
      <CardContent>
        <Typography variant="subtitle1">{item.itemCategory}</Typography>
        <Divider />
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Typography variant="body1">Sold:</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {item.qty} {item.unit}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          size="small"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {item.details.map((row, index) => (
            <>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                spacing={1}
              >
                <Grid item>{row.documentNo}</Grid>
                <Grid item>
                  <Typography variant="caption" color="textSecondary">
                    {moment(row.date).format("DD-MMM-yyyy")}
                  </Typography>
                </Grid>
                <Grid item>{row.storeName}</Grid>
                {row.customerName && (
                  <Grid item>Customer Name: {row.customerName}</Grid>
                )}
              </Grid>
              <Divider />
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>Price: {row.price.toLocaleString("EN-US")}</Grid>
                <Grid item>Disc: {row.disc}%</Grid>
              </Grid>

              <Divider />
            </>
          ))}
        </CardContent>
      </Collapse>
    </Card>
  );
});
