import {Box, Divider, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(4, 0, 2, 0),
  },
}));

export default function NotFound() {
  const classes = useStyles();

  return (
    <div>
      <Box display="flex" justifyContent="center" width="100%">
        <Box
          display="flex"
          alignItems="center"
          sx={{height: "50vh"}}
          width="auto"
        >
          <Box flexGrow={1} m={0} p={0}>
            <Box>
              <Typography align="left" variant="h1">
                404 <img alt="search icon" src="/loupe.svg" style={{width: "7vh"}} />
              </Typography>
            </Box>
            <Typography align="left" variant="h2">
              Page not found.
            </Typography>
            <Divider className={classes.divider} />
            <Box display="flex" m={0} p={0}>
              <Typography variant="h6">
                The page you are looking for doesn't exist or has been moved.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" width="auto" m={0} p={0}>
          <img alt="sad icon" src="/sad404.svg" style={{width: "55vh"}} />
        </Box>
      </Box>
    </div>
  );
}
