import React from "react";
import {useEffect} from "react";
import {useStore} from "../../app/stores/store";
import Grid from "@material-ui/core/Grid";
import MasterMenuTable from "./MasterMenuTable";
import SearchInput from "../../app/common/form/SearchInput";
import {Form, Formik} from "formik";
import {observer} from "mobx-react-lite";

export default observer(function MasterMenuList() {
  const {menuStore} = useStore();
  const {menuDataTable, loadDataTable, setPredicate} = menuStore;
  const [search, setSearch] = React.useState({search: ""});

  useEffect(() => {
    if (menuDataTable.size <= 1) {
      setPredicate(search.search);
      loadDataTable();
    }
  }, [menuDataTable, loadDataTable, setPredicate, search.search]);

  const handleSearchClick = (values: any) => {
    setPredicate(values.search);
    setSearch({search: values.search});
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        <Grid item lg={4} md={6} xs={12}>
          <Formik
            enableReinitialize
            initialValues={search}
            onSubmit={(values) => handleSearchClick(values)}
          >
            {({handleSubmit}) => (
              <Form
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <SearchInput placeholder="Search..." name="search" autoFocus />
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <MasterMenuTable />
        </Grid>
      </Grid>
    </>
  );
});
