import {
  Avatar,
  Chip,
  createStyles,
  FormControl,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useStyles } from "../../app/layout/style";
import { ItemDropdown } from "../../app/models/item";

const useLocal = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: "100%",
    },
  })
);

interface Props {
  options?: ItemDropdown[];
  value: ItemDropdown | null;
  setValue: (value: ItemDropdown | null) => void;
  optionDisabled?: ItemDropdown[];
  loading: boolean;
}

export default function ItemsAutoComplete({
  options,
  value,
  setValue,
  optionDisabled,
  loading,
}: Props) {
  const classes = useStyles();
  const local = useLocal();
  const handleOptionDisabled = (group: ItemDropdown) => {
    if (optionDisabled)
      return optionDisabled.some((x) => x === group);
    else return false;
  };

  return (
    <FormControl fullWidth className={classes.icon}>
      <Autocomplete
        id="search-groups"
        options={options ? options : []}
        getOptionLabel={(option) => option.itemCode}
        getOptionSelected={(option, value) =>
          option.itemCode === value.itemCode
        }
        getOptionDisabled={(option) => handleOptionDisabled(option)}
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue !== "string") {
            setValue(newValue);
          }
        }}
        renderOption={(option) => (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            className={local.card}
          >
            <Grid item>
              <Avatar
                alt={option.itemCode}
                src={option.image ? option.image.url : "/no-image.png"}
              />
            </Grid>
            <Grid item xs>
              <Typography variant="body2" component="p">
                {option.itemCode}
              </Typography>
              <Typography variant="caption" component="p">
                {option.color.description}
              </Typography>
            </Grid>
            <Grid item>
              <Chip
                variant="outlined"
                color="primary"
                label={option.size.description}
              />
            </Grid>
          </Grid>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Item Code"
            placeholder="Please select any item code."
            margin="dense"
          />
        )}
        disabled={loading}
      />
    </FormControl>
  );
}
