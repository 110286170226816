import React from "react";
import {
  Avatar,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { SalesTrend } from "../../app/models/sale";
import { HeadCell } from "../../app/models/table";
import moment from "moment";

interface Props {
  row: SalesTrend;
  headCells: HeadCell<any>[];
}

export default observer(function ReportSalesTrendTableRow(props: Props) {
  const { row, headCells } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell component="th" scope="row" align="center">
          <Avatar
            alt={row.itemCode}
            src={row.image ? row.image : "/no-image.png"}
          />
        </TableCell>
        <TableCell align="center">{row.itemCategory}</TableCell>
        <TableCell align="center">{row.itemCode}</TableCell>
        <TableCell align="left">{row.itemDescription}</TableCell>
        <TableCell align="center">{row.color}</TableCell>
        <TableCell align="center">{row.unit}</TableCell>
        <TableCell align="center">{row.size}</TableCell>
        <TableCell align="center">{row.qty}</TableCell>
        {row.details.length > 0 ? (
          <TableCell>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell></TableCell>
        )}
      </TableRow>
      {row.details.length > 0 && (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={headCells.length}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                marginLeft={5}
                marginTop={1}
                marginRight={5}
                marginBottom={2}
              >
                <Typography variant="h6" gutterBottom component="div">
                  History
                </Typography>
                <Table size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Store Name</TableCell>
                      <TableCell align="center">Invoice No.</TableCell>
                      <TableCell align="center">Customer</TableCell>
                      <TableCell align="right">Price (IDR)</TableCell>
                      <TableCell align="right">Disc (%)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.details.map((detail, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {moment(detail.date).format("DD-MMM-yyyy")}
                        </TableCell>
                        <TableCell align="center">{detail.storeName}</TableCell>
                        <TableCell align="center">
                          {detail.documentNo}
                        </TableCell>
                        <TableCell align="center">
                          {detail.customerName}
                        </TableCell>
                        <TableCell align="right">
                          {detail.price.toLocaleString("EN-US")}
                        </TableCell>
                        <TableCell align="right">{detail.disc}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
});
