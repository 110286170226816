import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStyles } from "../../app/layout/style";
import { StockBalance } from "../../app/models/inventories";

interface Props {
  item: StockBalance;
}

export default observer(function ReportStockBalanceCard({ item }: Props) {
  const classes = useStyles();
  const totalQty = item.sizes.reduce(
    (total, currentData) => (total = total + currentData.qty),
    0
  );

  return (
    <Card style={{ height: "100%" }}>
      <CardHeader
        avatar={
          <Avatar
            src={`${item.image ? item.image : "/no-image.png"}`}
            className={classes.large}
          ></Avatar>
        }
        title={item.itemCode}
        subheader={item.color}
      />
      <CardContent>
        <Typography variant="subtitle1">{item.itemCategory}</Typography>
        <Divider />
        {item.sizes.map((row) => (
          <div key={row.id}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography variant="body1">Size : {row.size}</Typography>
              </Grid>
              <Grid item>
                {row.qty < 0 ? (
                  <Typography variant="body1" color="error">
                    {row.qty.toLocaleString("EN-US")} {item.unit}
                  </Typography>
                ) : (
                  <Typography variant="body1">
                    {row.qty.toLocaleString("EN-US")} {item.unit}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </div>
        ))}
        <Divider />
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Typography variant="subtitle1">Total:</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              {totalQty.toLocaleString("en-US")} {item.unit}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});
