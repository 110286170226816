interface Category {
  id: string;
  description: string;
}

export interface Unit extends Category {}

export interface Size extends Category {}

export interface ItemCategory extends Category {
  icon: string;
  sizes: Size[];
}

export interface ItemCategoryWithNoSize extends Category {
  icon: string;
}

export interface Color extends Category {
  code: string;
}

export interface Image {
  id: string;
  url: string;
}

export interface ItemDocument {
  id: string;
  itemCategory: ItemCategoryWithNoSize;
  color: Color;
  itemDescription: string;
  itemCode: string;
  unit: Unit;
  image?: Image;
}

export interface ItemDropdown extends ItemDocument {
  size: Size;
  price: number;
  disc: number;
}

export interface ItemSizes {
  itemId?: string;
  sizeId: string;
  size: string;
  price?: number;
  cost?: number;
  disc?: number;
}

export interface Item extends ItemDocument {
  isActive: boolean;
  fullCode: string;
  sizes: ItemSizes[];
}

export class ItemSizesValues {
  itemId?: string = undefined;
  sizeId: string = "";
  price?: number = undefined;
  cost?: number = undefined;
  disc?: number = undefined;

  constructor(itemSizes?: ItemSizesValues) {
    if (itemSizes) {
      this.itemId = itemSizes.itemId;
      this.sizeId = itemSizes.sizeId;
      this.price = itemSizes.price;
      this.cost = itemSizes.cost;
      this.disc = itemSizes.disc;
    }
  }
}

export class ItemFormValues {
  id?: string = undefined;
  unitId: string = "";
  itemCategoryId: string = "";
  colorId: string = "";
  itemDescription: string = "";
  itemCode: string = "";
  imageId?: string = undefined;
  isActive: boolean = true;
  sizes: ItemSizesValues[] = [];

  constructor(item?: ItemFormValues) {
    if (item) {
      this.id = item.id;
      this.unitId = item.unitId;
      this.itemCategoryId = item.itemCategoryId;
      this.colorId = item.colorId;
      this.itemDescription = item.itemDescription;
      this.itemCode = item.itemCode;
      this.imageId = item.imageId;
      this.isActive = item.isActive;
      this.sizes = item.sizes;
    }
  }
}
