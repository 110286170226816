import { createStyles, Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

export type StyleProps = {
  prefersDarkMode: boolean;
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    paperCard: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    table_small: {
      minWidth: 750,
      fontSize: 12,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    progress: {
      marginRight: theme.spacing(1),
    },
    form: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
    searchInput: {
      width: "100%",
    },
    absolute: {
      position: "absolute",
      bottom: theme.spacing(5),
      right: theme.spacing(2),
    },
    title: {
      flex: "1 1 100%",
    },
    itemCard: {
      margin: theme.spacing(1, 0, 1),
      padding: theme.spacing(2),
    },
    report: {
      marginTop: theme.spacing(2),
    },
    avatar: {
      backgroundColor: theme.palette.secondary.main,
    },
    large: {
      backgroundColor: theme.palette.background.default,
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    chip_start: {
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    chip: {
      display: "flex",
      justifyContent: "flex-end",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
      fontSize: "16pt",
    },
    chip_column: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
      fontSize: "16pt",
    },
    content: {
      padding: theme.spacing(1),
    },
    media: {
      height: 0,
      paddingTop: "100%",
    },
    toolbar: {
      marginBottom: theme.spacing(1),
      padding: 0,
    },
    icon: {
      marginRight: theme.spacing(2),
    },
    price: {
      color: theme.palette.primary.light,
    },
    transferList: {
      marginBottom: theme.spacing(2),
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    logo: {
      marginRight: theme.spacing(1),
      width: 50,
    },
    dashboard_sales_revenue: {
      padding: theme.spacing(2),
      [theme.breakpoints.up("xl")]: {
        height: "42vh",
      },
      height: "33vh",
    },
    dashboard_payment: {
      padding: theme.spacing(2),
      minHeight: "27vh",
    },
    payment_table: {
      maxHeight: "23vh",
    },
    payment_chart: {
      height: "22vh",
    },
    dashboard_stock_balance: {
      padding: theme.spacing(2),
      [theme.breakpoints.up("xl")]: {
        height: "41.5vh",
      },
      height: "39vh",
    },
    dashboard_sales_trends: {
      marginBottom: theme.spacing(1),
      padding: theme.spacing(2),
      [theme.breakpoints.up("xl")]: {
        height: "41.2vh",
      },
      height: "38.5vh",
    },
    center_div: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "85%",
    },
  })
);
