import { Paper, Table, TableBody, TableContainer } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../app/common/table/EnhancedTableHead";
import { useStyles } from "../../app/layout/style";
import { HeadCell, Order } from "../../app/models/table";
import { useStore } from "../../app/stores/store";
import ReportStockBalanceTableHead from "./ReportStockBalanceTableHead";
import ReportStockBalanceTableSkeleton from "./ReportStockBalanceTableSkeleton";
import { SalesTrend } from "../../app/models/sale";
import ReportSalesTrendTableRow from "./ReportSalesTrendTableRow";

export default observer(function ReportSalesTrendTable() {
  const classes = useStyles();
  const { salesStore } = useStore();
  const { loadSalesTrend, salesTrend, loadingSalesTrend } = salesStore;
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof SalesTrend>("itemCode");
  const headCells: HeadCell<SalesTrend>[] = [
    {
      id: "image",
      align: "center",
      disablePadding: false,
      label: "",
      disableSort: true,
      skeletonShape: "circle",
    },
    {
      id: "itemCategory",
      align: "center",
      disablePadding: false,
      label: "Category",
      disableSort: true,
      skeletonShape: "text",
    },
    {
      id: "itemCode",
      align: "center",
      disablePadding: false,
      label: "Item Code",
      disableSort: true,
      skeletonShape: "text",
    },
    {
      id: "itemDescription",
      align: "left",
      disablePadding: false,
      label: "Description",
      disableSort: true,
      skeletonShape: "text",
    },
    {
      id: "color",
      align: "center",
      disablePadding: false,
      label: "Color",
      disableSort: true,
      skeletonShape: "text",
    },
    {
      id: "unit",
      align: "center",
      disablePadding: false,
      label: "Unit",
      disableSort: true,
      skeletonShape: "text",
    },
    {
      id: "size",
      align: "center",
      disablePadding: false,
      label: "Size",
      disableSort: true,
      skeletonShape: "text",
    },
    {
      id: "qty",
      align: "center",
      disablePadding: false,
      label: "Qty (Sold)",
      disableSort: true,
      skeletonShape: "text",
    },
    {
      id: "details",
      align: "center",
      disablePadding: true,
      label: "",
      disableSort: true,
      skeletonShape: "rect",
    },
  ];

  useEffect(() => {
    if (salesTrend.length === 0) loadSalesTrend();
  }, [loadSalesTrend, salesTrend.length]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property as keyof SalesTrend);
  };

  return (
    <Paper className={classes.toolbar}>
      <TableContainer>
        <Table className={classes.table} size="medium" component="table">
          <ReportStockBalanceTableHead
            headCells={headCells}
            loading={loadingSalesTrend}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {loadingSalesTrend ? (
              <ReportStockBalanceTableSkeleton row={6} cellArray={headCells} />
            ) : salesTrend.length === 0 ? (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={headCells.length + 1}>
                  No Data.
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              salesTrend.map((row, index) => (
                <ReportSalesTrendTableRow
                  row={row}
                  headCells={headCells}
                  key={index}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
});
