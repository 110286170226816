import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { Store, StoreFormValues } from "../models/location";

export default class StoreStore {
  storeRegistry = new Map<string, Store>();
  storeDropDown: Store[] = [];
  loadingStore = false;
  predicate = new Map<string, string>();

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadStores();
      }
    );
  }

  setPredicate = (value: string) => {
    this.predicate.delete("search");
    this.predicate.set("search", value);
  };

  get axiosParams() {
    const params = new URLSearchParams();
    if (this.predicate.size <= 1) {
      this.predicate.forEach((value, key) => {
        params.append(key, value);
      });
    }

    return params;
  }

  loadStores = async () => {
    this.loadingStore = true;

    try {
      const result = await agent.Stores.list(this.axiosParams);
      runInAction(() => {
        this.storeRegistry.clear();
        result.forEach((location) => {
          this.storeRegistry.set(location.id, location);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingStore = false));
    }
  };

  loadStore = async (id: string) => {
    this.loadingStore = true;

    try {
      const result = await agent.Stores.details(id);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingStore = false));
    }
  };

  loadDropDown = async () => {
    try {
      const dropdown = await agent.Stores.dropdown();
      runInAction(() => {
        this.storeDropDown = dropdown;
      });
    } catch (error) {
      console.log(error);
    }
  };

  get storeList() {
    return Array.from(this.storeRegistry);
  }

  createStore = async (store: StoreFormValues) => {
    try {
      await agent.Stores.create(store).then(() => {
        runInAction(() => {
          let newStore = new Store(store);
          this.storeRegistry.set(newStore.id, newStore);
        });
      });
      return "Create store success!";
    } catch (error) {
      throw error;
    }
  };

  updateStore = async (store: StoreFormValues) => {
    try {
      await agent.Stores.update(store).then(() => {
        runInAction(() => {
          if (store.id) {
            this.storeRegistry.set(store.id, store as Store);
          }
        });
      });
      return "Store updated!";
    } catch (error) {
      throw error;
    }
  };

  loadStoresGranted = async () => {
    try {
      const dropdown = await agent.Stores.granted();
      runInAction(() => {
        this.storeDropDown = dropdown;
      });
      return dropdown[0].id;
    } catch (error) {
      console.log(error);
    }
  };
}
