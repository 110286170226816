import {
  Paper,
  Box,
  Typography,
  Button,
  Divider,
  Grid,
  TextField,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import DropDownAutoComplete, {
  icon,
  checkedIcon,
} from "../../app/common/form/DropDownAutoComplete";
import InputField from "../../app/common/form/InputField";
import MySwitch from "../../app/common/form/MySwitch";
import { useStyles } from "../../app/layout/style";
import { UserFormValues } from "../../app/models/user";
import { useStore } from "../../app/stores/store";
import RoleForm from "./RoleForm";
import * as Yup from "yup";
import AddIcon from "@material-ui/icons/Add";

export default observer(function UserForm() {
  const classes = useStyles();
  const { userStore, snackbarStore, dialogStore } = useStore();
  const { register, update, roles, getRoles, loadUser, loadingForm } =
    userStore;
  const { openSnackbar } = snackbarStore;
  const { openDialog } = dialogStore;
  const [user, setUser] = useState<UserFormValues>(new UserFormValues());
  const history = useHistory();
  let { id: username } = useParams<{ id: string }>();

  const schema = () => {
    if (username) {
      return Yup.object({
        firstName: Yup.string().required("First Name is required."),
        userName: Yup.string().required("Username is required."),
        email: Yup.string().required("Email is required.").email(),
        password: Yup.string()
          .min(6, "Password must be at least 6 characters.")
          .matches(/[0-9]+/, "Password must contain at least one number."),
        passwordConfirmation: Yup.string().oneOf(
          [Yup.ref("password"), null],
          "Passwords must match"
        ),
      });
    }
    return Yup.object({
      firstName: Yup.string().required("First Name is required."),
      userName: Yup.string().required("Username is required."),
      email: Yup.string()
        .required("Email is required.")
        .email("Email is not valid email address."),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters.")
        .matches(/[0-9]+/, "Password must contain at least one number."),
      passwordConfirmation: Yup.string()
        .required("Password confirmation is required.")
        .oneOf(
          [Yup.ref("password"), null],
          "Password and confirmation password do not match."
        ),
    });
  };

  useEffect(() => {
    getRoles();
    if (username) {
      loadUser(username).then((user) => {
        setUser(new UserFormValues(user));
      });
    }
  }, [getRoles, username, loadUser]);

  const HandleAddRole = () => {
    openDialog(<RoleForm />, "xs");
  };

  const handleFormSubmit = (
    user: UserFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (!username) {
      register(user)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            setUser(new UserFormValues());
            resetForm();
          }
        });
    } else {
      update(username, user)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            history.push("/registeruser");
          }
        });
    }
  };

  return (
    <Paper className={classes.form}>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h5">Form Create</Typography>
        </Box>
        <Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={HandleAddRole}
            endIcon={<AddIcon />}
          >
            Add Role
          </Button>
        </Box>
      </Box>
      <Divider />
      <Formik
        validationSchema={schema}
        enableReinitialize
        initialValues={user}
        onSubmit={(values, { resetForm, setSubmitting }) =>
          handleFormSubmit(values, resetForm, setSubmitting)
        }
      >
        {({ handleSubmit, isSubmitting, isValid, dirty }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <DropDownAutoComplete
                  multiple
                  disableCloseOnSelect
                  options={roles}
                  name="roles"
                  disabled={isSubmitting || loadingForm}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Roles"
                      placeholder="Please select any role(s)"
                      margin="normal"
                    />
                  )}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                />
                <InputField
                  variant="outlined"
                  name="userName"
                  label="Username"
                  placeholder="Please input username"
                  disabled={isSubmitting || loadingForm}
                />
                <InputField
                  variant="outlined"
                  name="password"
                  label="Password"
                  placeholder="Please input password"
                  type="password"
                  disabled={isSubmitting || loadingForm}
                />
                <InputField
                  variant="outlined"
                  name="passwordConfirmation"
                  label="Password Confirmation"
                  placeholder="Please input password confirmation"
                  type="password"
                  disabled={isSubmitting || loadingForm}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputField
                  variant="outlined"
                  name="email"
                  label="Email"
                  placeholder="Please input email"
                  type="Email"
                  disabled={isSubmitting || loadingForm}
                />
                <InputField
                  variant="outlined"
                  name="firstName"
                  label="First Name"
                  placeholder="Please input first name"
                  disabled={isSubmitting || loadingForm}
                />
                <InputField
                  variant="outlined"
                  name="lastName"
                  label="Last Name"
                  placeholder="Please input last name"
                  disabled={isSubmitting || loadingForm}
                />
                <MySwitch
                  name="isActive"
                  label="Active User"
                  disabled={isSubmitting || loadingForm}
                />
                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="stretch"
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      {isSubmitting && (
                        <CircularProgress
                          className={classes.progress}
                          size={16}
                          color="inherit"
                        />
                      )}
                      Save
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="default"
                      component={Link}
                      to="/registeruser"
                      disabled={isSubmitting || loadingForm}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
});
