import TextField, {
  BaseTextFieldProps,
} from "@material-ui/core/TextField/TextField";
import { useField } from "formik";

interface Props extends BaseTextFieldProps {
  placeholder?: string;
  name: string;
  type?: string;
  label?: string;
  helperText?: string;
  onChange?: (e: React.ChangeEvent<{ value: unknown }>) => void;
}

export default function InputField(props: Props) {
  const [field, meta, helpers] = useField(props.name);

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    helpers.setValue(e.target.value);
    if (props.onChange !== undefined) {
      props.onChange(e);
    }
  };

  return (
    <TextField
      {...field}
      {...props}
      id={props.name}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error ? meta.error : props.helperText}
      onChange={(e) => handleChange(e)}
      onBlur={() => helpers.setTouched(true)}
      fullWidth
      margin={props.margin ? props.margin : "normal"}
      color="primary"
    />
  );
}
