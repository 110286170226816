import axios, { AxiosError, AxiosResponse } from "axios";
import { history } from "../..";
import { DropDownProps } from "../models/dropDownProps";
import {
  Color,
  Image,
  Item,
  ItemCategory,
  ItemDropdown,
  ItemFormValues,
  Size,
  Unit,
} from "../models/item";
import {
  StockLocation,
  StockLocationFormValues,
  Store,
  StoreFormValues,
} from "../models/location";
import { ManageMenuFormValues } from "../models/manageMenu";
import {
  MasterMenuData,
  MasterMenu,
  MasterMenuFormValues,
} from "../models/masterMenu";
import { PaginatedResult } from "../models/pagination";
import {
  UserLocationAccessRightFormValues,
  UserStoreAccessRightFormValues,
} from "../models/locationAccessRight";
import {
  LoginFormValues,
  ProfileFormValues,
  Role,
  RoleForm,
  User,
  UserFormValues,
} from "../models/user";
import { store } from "../stores/store";
import {
  StockControl,
  StockControlFormValues,
  StockTransactionType,
} from "../models/stockControl";
import {
  BankAccount,
  PaymentType,
  Sale,
  SalesFormValues,
  SalesTrend,
} from "../models/sale";
import { CashFlow, CashFlowFormValues, CashFlowType } from "../models/cashFlow";
import {
  SalesPaymentChart,
  SalesRevenueChart,
  SalesRevenueData,
  SalesTrendChart,
  StockBalanceChart,
} from "../models/dashboard";
import { StockBalance } from "../models/inventories";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
  const token = store.commonStore.token;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    const pagination = response.headers["pagination"];
    if (pagination) {
      response.data = new PaginatedResult(
        response.data,
        JSON.parse(pagination)
      );
      return response as AxiosResponse<PaginatedResult<any>>;
    }
    return response;
  },
  (error: AxiosError) => {
    const { data, status, config } = error.response!;
    switch (status) {
      case 400:
        if (typeof data === "string") {
          throw data;
        }
        if (config.method === "get" && data.errors.hasOwnProperty("id")) {
          history.push("/not-found");
        }
        if (data.errors) {
          const modalStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modalStateErrors.push(data.errors[key]);
            }
          }
          throw modalStateErrors.flat();
        }
        if (
          data[0].hasOwnProperty("code") &&
          data[0].hasOwnProperty("description")
        ) {
          const modalStateErrors = [];
          for (const key in data) {
            if (data[key].description) {
              modalStateErrors.push(data[key].description);
            }
          }
          throw modalStateErrors.flat();
        }
        break;
      case 401:
        if (status === 401 && typeof data === "string") {
          throw data;
        }
        break;
      case 403:
        if (status === 403) {
          return "Forbidden";
        }
        break;
      case 404:
        history.push("/not-found");
        break;
      case 500:
        if (data !== undefined) throw data;
        break;
    }
    return Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Menus = {
  list: () => requests.get<MasterMenu[]>("/menus"),
  create: (menu: MasterMenuFormValues) => requests.post<void>("/menus", menu),
  update: (menu: MasterMenuFormValues) =>
    requests.put<void>(`/menus/${menu.id}`, menu),
  details: (id: string) => requests.get<MasterMenu>(`/menus/details/${id}`),
  datatable: (params: URLSearchParams) =>
    axios
      .get<MasterMenuData[]>("/menus/datatable", { params })
      .then(responseBody),
  delete: (id: string) => requests.del<void>(`/menus/${id}`),
};

const Account = {
  current: () => requests.get<User>("/accounts"),
  login: (user: LoginFormValues) =>
    requests.post<User>("/accounts/login", user),
  register: (user: UserFormValues) =>
    requests.post<User>("/accounts/register", user),
  update: (username: string, user: UserFormValues) =>
    requests.put<User>(`/accounts/${username}`, user),
  user: (username: string) =>
    requests.get<UserFormValues>(`/accounts/user/${username}`),
  roles: () => requests.get<Role[]>("/accounts/roles"),
  list: (params: URLSearchParams) =>
    axios.get<User[]>("/accounts/list", { params }).then(responseBody),
  dropdown: () => requests.get<DropDownProps[]>("/accounts/dropdown"),
  rolecreate: (role: RoleForm) =>
    requests.post<RoleForm>("accounts/create-role", role),
  changeProfile: (user: ProfileFormValues) =>
    requests.put<User>("/accounts/profile", user),
};

const Location = {
  list: (params: URLSearchParams) =>
    axios
      .get<StockLocation[]>("/stocklocations", { params })
      .then(responseBody),
  details: (id: string) =>
    requests.get<StockLocation>(`/stocklocations/details/${id}`),
  create: (location: StockLocationFormValues) =>
    requests.post<void>("/stocklocations", location),
  update: (location: StockLocationFormValues) =>
    requests.put<void>(`/stocklocations/${location.id}`, location),
  dropdown: () => requests.get<StockLocation[]>("/stocklocations/dropdown"),
  granted: () => requests.get<StockLocation[]>("/stocklocations/granted"),
};

const Stores = {
  list: (params: URLSearchParams) =>
    axios.get<Store[]>("/stores", { params }).then(responseBody),
  details: (id: string) => requests.get<Store>(`/stores/details/${id}`),
  create: (store: StoreFormValues) => requests.post<void>("/stores", store),
  update: (store: StoreFormValues) =>
    requests.put<void>(`/stores/${store.id}`, store),
  dropdown: () => requests.get<Store[]>("/stores/dropdown"),
  granted: () => requests.get<Store[]>("/stores/granted"),
};

const ManageMenu = {
  loadMenusNotGranted: () =>
    requests.get<DropDownProps[]>("/managemenus/notgranted/"),
  loadMenusGranted: (id: string) =>
    requests.get<DropDownProps[]>(`/managemenus/granted/${id}`),
  create: (menus: ManageMenuFormValues[]) =>
    requests.post<void>("/managemenus/", menus),
  delete: (menus: ManageMenuFormValues[]) =>
    requests.post<void>("/managemenus/delete", menus),
};

const Items = {
  list: (params: URLSearchParams) =>
    axios.get<PaginatedResult<Item[]>>("/items", { params }).then(responseBody),
  details: (id: string) => requests.get<Item>(`/items/${id}`),
  loadUnits: () => requests.get<Unit[]>("/items/units"),
  loadCategories: () => requests.get<ItemCategory[]>("/items/categories"),
  loadColors: () => requests.get<Color[]>("/items/colors"),
  loadSizes: () => requests.get<Size[]>("/items/sizes"),
  create: (item: ItemFormValues) => requests.post<Item>("/items", item),
  update: (item: ItemFormValues) =>
    requests.put<Item>(`/items/${item.id}`, item),
  delete: (id: string) => requests.del<void>(`/items/${id}`),
  dropdown: () => requests.get<ItemDropdown[]>("/items/dropdown"),
};

const Photos = {
  uploadPhoto: (file: any) => {
    let formData = new FormData();
    formData.append("File", file);
    return axios.post<Image>("/photos", formData, {
      headers: { "Content-type": "multipart/form-data" },
    });
  },
  deletePhoto: (id: string) => requests.del<void>(`/photos/${id}`),
};

const StoreAccessRights = {
  loadUsersGranted: (id: string) =>
    requests.get<DropDownProps[]>(`/storeaccessrights/granted/${id}`),
  create: (users: UserStoreAccessRightFormValues[]) =>
    requests.post<void>("/storeaccessrights", users),
  delete: (users: UserStoreAccessRightFormValues[]) =>
    requests.post<void>("/storeaccessrights/delete", users),
};

const StockLocationAccessRights = {
  loadUsersGranted: (id: string) =>
    requests.get<DropDownProps[]>(`/stocklocationaccessrights/granted/${id}`),
  create: (users: UserLocationAccessRightFormValues[]) =>
    requests.post<void>("/stocklocationaccessrights", users),
  delete: (users: UserLocationAccessRightFormValues[]) =>
    requests.post<void>("/stocklocationaccessrights/delete", users),
};

const StockControls = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<StockControl[]>>("/stockcontrols", { params })
      .then(responseBody),
  loadTransactionType: () =>
    requests.get<StockTransactionType[]>("/stockcontrols/transactiontype"),
  create: (document: StockControlFormValues) =>
    requests.post<StockControl>("/stockcontrols", document),
  details: (id: string) => requests.get<StockControl>(`/stockcontrols/${id}`),
  update: (document: StockControlFormValues) =>
    requests.put<StockControl>(`/stockcontrols/${document.id}`, document),
  delete: (ids: string[]) => requests.post<void>("/stockcontrols/delete", ids),
  post: (id: string) => requests.post<void>(`/stockcontrols/post/${id}`, {}),
};

const Sales = {
  paymentTypes: () => requests.get<PaymentType[]>("sales/paymenttypes"),
  bankAccounts: () => requests.get<BankAccount[]>("sales/bankaccounts"),
  list: (params: URLSearchParams) =>
    axios.get<PaginatedResult<Sale[]>>("/sales", { params }).then(responseBody),
  details: (id: string) => requests.get<Sale>(`/sales/${id}`),
  create: (document: SalesFormValues) => {
    return requests.post<Sale>("sales", document);
  },
  update: (document: SalesFormValues) =>
    requests.put<Sale>(`/sales/${document.id}`, document),
  delete: (ids: string[]) => requests.post<void>("/sales/delete", ids),
  salesRevenue: (params: URLSearchParams) =>
    axios
      .get<SalesRevenueData>("/sales/revenue", { params })
      .then(responseBody),
  salesRevenueChart: (params: URLSearchParams) =>
    axios
      .get<SalesRevenueChart[]>("/sales/revenue_chart", { params })
      .then(responseBody),
  salesPaymentChart: (params: URLSearchParams) =>
    axios
      .get<SalesPaymentChart[]>("/sales/payment_chart", { params })
      .then(responseBody),
  salesTrendChart: (params: URLSearchParams) =>
    axios
      .get<SalesTrendChart[]>("/sales/salestrend_chart", { params })
      .then(responseBody),
  salesTrend: (params: URLSearchParams) =>
    axios.get<SalesTrend[]>("/sales/salestrend", { params }).then(responseBody),
};

const Cashflows = {
  list: (params: URLSearchParams) =>
    axios.get<CashFlow[]>("/cashflows", { params }).then(responseBody),
  details: (id: string) => requests.get<CashFlow>(`/cashflows/${id}`),
  cashflowType: () => requests.get<CashFlowType[]>("cashflows/type"),
  create: (document: CashFlowFormValues) => {
    return requests.post<void>("cashflows", document);
  },
  update: (document: CashFlowFormValues) =>
    requests.put<CashFlow>(`/cashflows/${document.id}`, document),
  delete: (ids: string[]) => requests.post<void>("/cashflows/delete", ids),
  post: (id: string) => requests.post<void>(`/cashflows/post/${id}`, {}),
};

const Inventories = {
  stockBalanceChart: (params: URLSearchParams) =>
    axios
      .get<StockBalanceChart[]>("/inventories/stockbalance_chart", { params })
      .then(responseBody),
  stockBalance: (params: URLSearchParams) =>
    axios
      .get<StockBalance[]>("/inventories/stockbalance", { params })
      .then(responseBody),
};

const agent = {
  Menus,
  Account,
  Location,
  Stores,
  ManageMenu,
  Items,
  Photos,
  StoreAccessRights,
  StockLocationAccessRights,
  StockControls,
  Sales,
  Cashflows,
  Inventories,
};

export default agent;
