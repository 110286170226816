import {makeAutoObservable, runInAction} from "mobx";
import {Order} from "../models/table";

export default class TableStore {
  selectedItem: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedItem = (item: string[]) => {
    runInAction(() => (this.selectedItem = item));
  };

  private descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  getComparator = <Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): ((a: {[key in Key]: any}, b: {[key in Key]: any}) => number) => {
    return order === "desc"
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => this.descendingComparator(b, a, orderBy);
  };

  stableSort = <T>(array: T[], comparator: (a: T, b: T) => number) => {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  selectItem = (id: string) => {
    const selectedIndex = this.selectedItem.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.selectedItem, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.selectedItem.slice(1));
    } else if (selectedIndex === this.selectedItem.length - 1) {
      newSelected = newSelected.concat(this.selectedItem.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.selectedItem.slice(0, selectedIndex),
        this.selectedItem.slice(selectedIndex + 1)
      );
    }
    this.setSelectedItem(newSelected);
  };

  isSelected = (id: string) => this.selectedItem.indexOf(id) !== -1;
}
