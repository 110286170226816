import {
  Paper,
  Typography,
  Divider,
  useMediaQuery,
  createTheme,
  Grid,
  Button,
  CircularProgress,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  BarChart,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";

export default observer(function StockBalance() {
  const classes = useStyles();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  );
  const { dashboardStore } = useStore();
  const { loadStockBalanceChart, stockBalanceChart, loadingStockBalanceChart } =
    dashboardStore;

  useEffect(() => {
    loadStockBalanceChart();
  }, [loadStockBalanceChart]);

  return (
    <Paper className={classes.dashboard_stock_balance}>
      <Grid justifyContent="space-between" alignItems="center" container>
        <Grid item>
          <Typography variant="subtitle2" component="h6">
            Stock Balance
          </Typography>
        </Grid>
        <Grid item>
          <Button
            component={Link}
            to="/report-stockbalance"
            color="primary"
            size="small"
          >
            View Report <ArrowForwardIcon />
          </Button>
        </Grid>
      </Grid>
      <Divider />
      {loadingStockBalanceChart ? (
        <div className={classes.center_div}>
          <CircularProgress />
        </div>
      ) : (
        <ResponsiveContainer width="100%" height="85%">
          <BarChart
            data={stockBalanceChart}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
            layout="vertical"
            barSize={20}
          >
            <XAxis type="number" stroke={theme.palette.text.primary} />
            <YAxis
              dataKey="itemCode"
              stroke={theme.palette.text.primary}
              type="category"
            />
            <Bar stackId="itemCode" dataKey="qty" fill="#00bcd4">
              <LabelList
                dataKey="qty"
                position="right"
                fill={theme.palette.text.primary}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </Paper>
  );
});
