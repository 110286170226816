import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import EnhancedTableToolbar from "../../app/common/table/EnhancedTableToolbar";
import EditIcon from "@material-ui/icons/Edit";
import { MasterMenuData } from "../../app/models/masterMenu";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import EnhancedTableHead from "../../app/common/table/EnhancedTableHead";
import { HeadCell, Order } from "../../app/models/table";
import { useStyles } from "../../app/layout/style";
import EnhancedTableSkeleton from "../../app/common/table/EnhancedTableSkeleton";
import { Tooltip, IconButton } from "@material-ui/core";
import DeleteDialog from "../../app/layout/DeleteDialog";

export default observer(function MasterMenuTable() {
  const classes = useStyles();
  const { menuStore, tableStore, snackbarStore, dialogStore } = useStore();
  const { dataTableList, loadingDataTable, deleteDataTable } = menuStore;
  const {
    stableSort,
    getComparator,
    selectedItem,
    setSelectedItem,
    selectItem,
    isSelected,
  } = tableStore;
  const { openSnackbar } = snackbarStore;
  const { openDialog, closeDialog } = dialogStore;
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof MasterMenuData>("menuName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const headCells: HeadCell<MasterMenuData>[] = [
    {
      id: "menuName",
      align: "left",
      disablePadding: false,
      label: "Menu Name",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "menuLink",
      align: "left",
      disablePadding: false,
      label: "Link",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "logo",
      align: "left",
      disablePadding: false,
      label: "Icon",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "type",
      align: "left",
      disablePadding: false,
      label: "Type",
      disableSort: false,
      skeletonShape: "text",
    },
  ];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property as keyof MasterMenuData);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (selectedItem.length === 0) {
        const newSelecteds = dataTableList.map((menu) => menu.id);
        setSelectedItem(newSelecteds);
        return;
      }
    }
    setSelectedItem([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    selectItem(id);
  };

  const handleClickDelete = (event: React.MouseEvent<unknown>) => {
    openDialog(
      <DeleteDialog title={"Are you sure want to delete this menu(s)"} />,
      "xs",
      handleDelete
    );
  };

  const handleDelete = () => {
    deleteDataTable(selectedItem)
      .catch((error) => {
        setSelectedItem([]);
        closeDialog();
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) openSnackbar(message, "success");
        setSelectedItem([]);
        closeDialog();
      });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          title="Master Menu List"
          numSelected={selectedItem.length}
          handleDelete={handleClickDelete}
          linkAddNew="/mastermenu/form"
          loading={loadingDataTable}
        />
        <TableContainer>
          <Table className={classes.table} size="medium" component="table">
            <EnhancedTableHead
              headCells={headCells}
              numSelected={selectedItem.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={dataTableList.length}
              loading={loadingDataTable}
            />
            <TableBody>
              {loadingDataTable ? (
                <EnhancedTableSkeleton row={5} cellArray={headCells} />
              ) : (
                stableSort(dataTableList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        </TableCell>
                        <TableCell padding="checkbox">
                          <Tooltip title="Update">
                            <IconButton
                              component={Link}
                              to={`/mastermenu/${row.id}`}
                              color="primary"
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row">
                          {row.menuName}
                        </TableCell>
                        <TableCell>{row.menuLink}</TableCell>
                        <TableCell>{row.logo}</TableCell>
                        <TableCell>{row.type}</TableCell>
                      </TableRow>
                    );
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dataTableList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
});
