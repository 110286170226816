import { CircularProgress, Paper, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Tooltip,
  TooltipProps,
  Cell,
} from "recharts";
import {
  ValueType,
  NameType,
} from "recharts/types/component/DefaultTooltipContent";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";

export default observer(function PaymentChart() {
  const classes = useStyles();
  const { dashboardStore } = useStore();
  const { paymentChart, loadPaymentChart, loadingPaymentChart } =
    dashboardStore;
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  useEffect(() => {
    loadPaymentChart();
  }, [loadPaymentChart]);

  if (loadingPaymentChart)
    return (
      <div className={classes.center_div}>
        <CircularProgress />
      </div>
    );

  return (
    <>
      {paymentChart.length === 0 ? (
        <div className={classes.center_div}>
          <Typography variant="h6" component="h6">
            No Data.
          </Typography>
        </div>
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              dataKey="value"
              data={paymentChart.map((x) => {
                return { name: x.paymentType, value: x.amount };
              })}
              label={(data) => data.name}
            >
              {paymentChart.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      )}
    </>
  );
});

function CustomTooltip({ active, payload }: TooltipProps<ValueType, NameType>) {
  const classes = useStyles();

  if (active) {
    return (
      <Paper className={classes.form}>
        <Typography variant="subtitle2" component="h6">
          {payload?.[0].name}
        </Typography>
        <Typography variant="subtitle2" component="p" color="primary">
          {`IDR ${payload?.[0].value?.toLocaleString()}`}
        </Typography>
      </Paper>
    );
  }

  return null;
}
