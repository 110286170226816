import React from "react";
import { observer } from "mobx-react-lite";
import {
  ItemFormValues,
  ItemSizes,
  ItemSizesValues,
  Size,
} from "../../app/models/item";
import {
  Paper,
  Grid,
  Chip,
  Tooltip,
  IconButton,
  Typography,
} from "@material-ui/core";
import PriceInputField, {
  QtyInputField,
} from "../../app/common/form/NumberInputField";
import { useStyles } from "../../app/layout/style";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import ItemSizeAutoComplete from "./ItemSizeAutoComplete";
import { useStore } from "../../app/stores/store";

interface Props {
  size: Size | null;
  setSize: (size: Size | null) => void;
  item: ItemFormValues;
  setItem: (item: ItemFormValues) => void;
  itemSizes: ItemSizes[];
  setItemSizes: (itemSizes: ItemSizes[]) => void;
  sizeGroup: Size[];
  setSizeGroup: (sizeGroup: Size[]) => void;
  isSubmitting: boolean;
  loading: boolean;
}

export default observer(function ItemFormAddDetail({
  size,
  setSize,
  item,
  setItem,
  itemSizes,
  sizeGroup,
  setSizeGroup,
  setItemSizes,
  isSubmitting,
  loading,
}: Props) {
  const classes = useStyles();
  const { itemStore } = useStore();
  const { itemCategories } = itemStore;

  const handleAddItems = () => {
    if (size) {
      const value = itemSizes.concat({
        sizeId: size.id,
        size: size.description,
      });
      setItemSizes(value);
      const itemFormValues = {
        ...item,
        sizes: item.sizes.concat(
          new ItemSizesValues({
            sizeId: size.id,
          })
        ),
      };
      setItem(new ItemFormValues(itemFormValues));
      setSizeGroup(sizeGroup.concat(size));
    }
    setSize(null);
  };

  const handleDeleteItem = (value: ItemSizes) => {
    setItemSizes(itemSizes.filter((x) => x.sizeId !== value.sizeId));
    setItem(
      new ItemFormValues({
        ...item,
        sizes: item.sizes.filter((x) => x.sizeId !== value.sizeId),
      })
    );
    setSizeGroup(sizeGroup.filter((x) => x.id !== value.sizeId));
  };

  return (
    <div className={classes.paper}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item lg={6} md={6} xs={12}>
          <Typography variant="h6" component="p">
            Add Price and Cost
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs>
              <ItemSizeAutoComplete
                options={
                  itemCategories.find((x) => x.id === item.itemCategoryId)
                    ?.sizes
                }
                value={size}
                setValue={setSize}
                optionDisabled={sizeGroup}
                loading={isSubmitting || loading}
              />
            </Grid>
            <Grid item>
              <Tooltip title="Add Items">
                <IconButton size="small" onClick={handleAddItems}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {itemSizes.map((detail, index) => (
        <Paper key={index} className={classes.itemCard} variant="outlined">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item lg={5} md={5} xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Chip
                    variant="outlined"
                    color="primary"
                    label={detail.size}
                  />
                </Grid>
                <Grid item xs>
                  <PriceInputField
                    variant="outlined"
                    label="Cost"
                    name={`sizes[${index}].cost`}
                    margin="dense"
                    disabled={isSubmitting || loading}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <PriceInputField
                variant="outlined"
                label="Price"
                name={`sizes[${index}].price`}
                margin="dense"
                disabled={isSubmitting || loading}
              />
            </Grid>
            <Grid item lg={3} md={3} xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs>
                  <QtyInputField
                    variant="outlined"
                    label="Disc"
                    name={`sizes[${index}].disc`}
                    margin="dense"
                    disabled={isSubmitting || loading}
                    andorment="%"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item>
                  <Tooltip title="Delete Item">
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteItem(detail)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </div>
  );
});
