import { Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import ReportSalesTrendCard from "./ReportSalesTrendCard";
import ReportStockBalanceCardSkeleton from "./ReportStockBalanceCardSkeleton";

export default observer(function ReportSalesTrendCardList() {
  const classes = useStyles();
  const { salesStore } = useStore();
  const { salesTrend, loadingSalesTrend } = salesStore;
  const skeletonCardArray = Array(6).fill("");

  return (
    <div className={classes.toolbar}>
      <Grid container spacing={1}>
        {loadingSalesTrend ? (
          skeletonCardArray.map((item, index) => (
            <Grid key={index} item md={4} sm={6} xs={6}>
              <ReportStockBalanceCardSkeleton />
            </Grid>
          ))
        ) : salesTrend.length === 0 ? (
          <Grid item xs={12}>
            <Typography variant="h5">No Data.</Typography>
          </Grid>
        ) : (
          salesTrend.map((item) => (
            <Grid key={item.itemCode} item md={4} sm={6} xs={6}>
              <ReportSalesTrendCard item={item} />
            </Grid>
          ))
        )}
      </Grid>
    </div>
  );
});
