import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useStyles } from "../../app/layout/style";
import {
  Item,
  ItemFormValues,
  ItemSizes,
  ItemSizesValues,
  Size,
} from "../../app/models/item";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Form, Formik } from "formik";
import Dropdown from "../../app/common/form/Dropdown";
import InputField from "../../app/common/form/InputField";
import MySwitch from "../../app/common/form/MySwitch";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "@material-ui/core";
import ItemFormAddDetail from "./ItemFormAddDetail";

export default observer(function ItemForm() {
  const classes = useStyles();
  const { itemStore, snackbarStore } = useStore();
  const {
    createItem,
    updateItem,
    loadItem,
    units,
    loadUnits,
    itemCategories,
    loadCategories,
    colors,
    loadColors,
    loadingItem,
  } = itemStore;
  const { openSnackbar } = snackbarStore;
  const [selectedImage, setSelectedImage] = useState<any>([]);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [item, setItem] = useState<ItemFormValues>(new ItemFormValues());
  const [size, setSize] = useState<Size | null>(null);
  const [itemSizes, setItemSizes] = useState<ItemSizes[]>([]);
  const [sizeGroup, setSizeGroup] = useState<Size[]>([]);
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadUnits();
    loadCategories();
    loadColors();

    if (selectedImage[0]) {
      setImageUrl(URL.createObjectURL(selectedImage[0]));
    }

    if (id) {
      loadItem(id).then((item) => {
        loadItemResult(item);
      });
    }
  }, [id, loadItem, loadUnits, loadCategories, loadColors, selectedImage]);

  const schema = Yup.object({
    itemCategoryId: Yup.string().required("Item Category is required."),
    colorId: Yup.string().required("Color is required."),
    unitId: Yup.string().required("Unit is required."),
    itemDescription: Yup.string().required("Item description is required."),
    itemCode: Yup.string().required("Item Code is required."),
    sizes: Yup.array().of(
      Yup.object().shape({
        cost: Yup.number()
          .typeError("Cost is must be a number.")
          .required("Cost is required."),
        price: Yup.number()
          .typeError("Price is must be a number.")
          .required("Price is required."),
      })
    ),
  });

  const loadItemResult = (item: Item | undefined) => {
    if (item) {
      setItemSizes(item.sizes);
      setSizeGroup(
        item.sizes.map((x) => {
          return {
            id: x.sizeId,
            description: x.size,
          } as Size;
        })
      );
      const value = new ItemFormValues({
        id: item.id,
        unitId: item.unit.id,
        itemCategoryId: item.itemCategory.id,
        colorId: item.color.id,
        itemDescription: item.itemDescription,
        itemCode: item.itemCode,
        isActive: item.isActive,
        imageId: item.image?.id,
        sizes: item.sizes.map(
          (x) =>
            new ItemSizesValues({
              itemId: x.itemId,
              sizeId: x.sizeId,
              cost: x.cost,
              price: x.price,
              disc: x.disc,
            })
        ),
      });
      setItem(value);
    }
  };

  const handleUploadImageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    validateForm: (values?: any) => void
  ) => {
    setSelectedImage(e.target.files);
    validateForm();
  };

  const handleFormSubmit = (
    item: ItemFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (!item.id) {
      const newId = uuid();
      let newItem = {
        ...item,
        id: newId,
        sizes: item.sizes.map(
          (x) => new ItemSizesValues({ ...x, itemId: newId })
        ),
      };
      createItem(newItem, selectedImage[0])
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            setItem(new ItemFormValues());
            setItemSizes([]);
            setSizeGroup([]);
            setImageUrl(null);
            resetForm();
          }
        });
    } else {
      updateItem(item, selectedImage[0])
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            history.push("/items");
          }
        });
    }
  };

  return (
    <>
      <Paper className={classes.form}>
        <Typography variant="h5">Form {id ? "Update" : "Create"}</Typography>
        <Divider />
        <Formik
          validationSchema={schema}
          enableReinitialize
          initialValues={item}
          onSubmit={(values, { resetForm, setSubmitting }) =>
            handleFormSubmit(values, resetForm, setSubmitting)
          }
        >
          {({ handleSubmit, isSubmitting, isValid, values, validateForm }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Dropdown
                    options={itemCategories.map((x) => {
                      return { text: x.description, value: x.id };
                    })}
                    name="itemCategoryId"
                    placeholder="Item Category"
                    label="Item Category"
                    disabled={isSubmitting || loadingItem}
                  />
                  <Dropdown
                    options={units.map((x) => {
                      return { text: x.description, value: x.id };
                    })}
                    name="unitId"
                    placeholder="Unit"
                    label="Unit"
                    disabled={isSubmitting || loadingItem}
                  />
                  <Dropdown
                    options={colors.map((x) => {
                      return { text: x.description, value: x.id };
                    })}
                    name="colorId"
                    placeholder="Color"
                    label="Color"
                    disabled={isSubmitting || loadingItem}
                  />
                  <InputField
                    variant="standard"
                    label="Item Code"
                    name="itemCode"
                    placeholder="Please input item code"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loadingItem}
                  />
                  <InputField
                    variant="standard"
                    label="Item Description"
                    name="itemDescription"
                    placeholder="Please input item description"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loadingItem}
                  />
                  {id && (
                    <MySwitch name="isActive" label="Active Item" checked />
                  )}
                  {imageUrl && selectedImage && (
                    <Box mt={2} textAlign="center">
                      <Typography
                        style={{ marginBottom: "5px" }}
                        component="h5"
                      >
                        Image Preview
                      </Typography>
                      <img
                        src={imageUrl}
                        alt={selectedImage.name}
                        height="200px"
                      />
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ItemFormAddDetail
                    size={size}
                    setSize={setSize}
                    item={values}
                    setItem={setItem}
                    itemSizes={itemSizes}
                    setItemSizes={setItemSizes}
                    sizeGroup={sizeGroup}
                    setSizeGroup={setSizeGroup}
                    isSubmitting={isSubmitting}
                    loading={loadingItem}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="stretch"
                spacing={1}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={
                      isSubmitting ||
                      !isValid ||
                      loadingItem ||
                      itemSizes.length === 0
                    }
                  >
                    {isSubmitting && (
                      <CircularProgress
                        className={classes.progress}
                        size={16}
                        color="inherit"
                      />
                    )}
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="default"
                    component="label"
                    disabled={isSubmitting || loadingItem}
                  >
                    Upload Image
                    <input
                      accept="image/*"
                      type="file"
                      hidden
                      onChange={(e) => handleUploadImageChange(e, validateForm)}
                    />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="default"
                    component={Link}
                    to="/items"
                    disabled={isSubmitting || loadingItem}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
});
