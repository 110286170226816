import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import InputField from "../../app/common/form/InputField";
import { useStyles } from "../../app/layout/style";
import { StoreFormValues } from "../../app/models/location";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import { useStore } from "../../app/stores/store";
import MySwitch from "../../app/common/form/MySwitch";
import Dropdown from "../../app/common/form/Dropdown";

export default observer(function StockLocationForm() {
  const classes = useStyles();
  const { storeStore, snackbarStore, stockLocationStore } = useStore();
  const { loadDropDown, stockLocationDropdown } = stockLocationStore;
  const { createStore, loadStore, loadingStore, updateStore } = storeStore;
  const { openSnackbar } = snackbarStore;
  const [store, setStore] = useState<StoreFormValues>(new StoreFormValues());
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadDropDown();

    if (id) {
      loadStore(id).then((store) => {
        setStore(new StoreFormValues(store));
      });
    }
  }, [loadStore, id, loadDropDown]);

  const schema = Yup.object({
    stockLocationId: Yup.string().required("Stock Location is required."),
    name: Yup.string().required("Store name is required."),
    city: Yup.string().required("City is required."),
  });

  const handleStockLocationChange = (
    e: React.ChangeEvent<{ value: unknown }>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const location = stockLocationDropdown.find((x) => x.id === e.target.value);
    if (location) {
      setFieldValue("name", location.name);
      setFieldValue("city", location.city);
    } else {
      setFieldValue("name", "");
      setFieldValue("city", "");
    }
  };

  const handleFormSubmit = (
    store: StoreFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (!store.id) {
      let newStore = {
        ...store,
        id: uuid(),
      };
      createStore(newStore)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            setStore(new StoreFormValues());
            resetForm();
          }
        });
    } else {
      updateStore(store)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            history.push("/store");
          }
        });
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.form}>
            <Typography variant="h5">
              Form {id ? "Update" : "Create"}
            </Typography>
            <Divider />
            <Formik
              validationSchema={schema}
              enableReinitialize
              initialValues={store}
              onSubmit={(values, { resetForm, setSubmitting }) =>
                handleFormSubmit(values, resetForm, setSubmitting)
              }
            >
              {({ handleSubmit, isSubmitting, setFieldValue, isValid, dirty }) => (
                <Form onSubmit={handleSubmit} autoComplete="off">
                  <Dropdown
                    options={stockLocationDropdown.map((x) => {
                      return { text: x.name, value: x.id };
                    })}
                    name="stockLocationId"
                    placeholder="Stock Location"
                    label="Stock Location"
                    onChange={(e) =>
                      handleStockLocationChange(e, setFieldValue)
                    }
                    disabled={isSubmitting || loadingStore}
                  />
                  <InputField
                    variant="standard"
                    label="Store"
                    name="name"
                    placeholder="Please input store name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loadingStore}
                  />
                  <InputField
                    variant="standard"
                    label="City"
                    name="city"
                    placeholder="Please input city"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isSubmitting || loadingStore}
                  />
                  {id && <MySwitch name="isActive" label="Active Store" />}
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="stretch"
                    spacing={1}
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting || !isValid || !dirty}
                      >
                        {isSubmitting && (
                          <CircularProgress
                            className={classes.progress}
                            size={16}
                            color="inherit"
                          />
                        )}
                        Save
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="default"
                        component={Link}
                        to="/store"
                        disabled={isSubmitting || loadingStore}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
});
