import { Typography, Chip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStyles } from "../../app/layout/style";
import { StockBalance } from "../../app/models/inventories";
import { useStore } from "../../app/stores/store";
import ReportStockBalanceCard from "./ReportStockBalanceCard";
import ReportStockBalanceCardSkeleton from "./ReportStockBalanceCardSkeleton";
import ImportExportIcon from "@material-ui/icons/ImportExport";

export default observer(function ReportStockBalanceCardList() {
  const classes = useStyles();
  const { inventoriesStore } = useStore();
  const {
    stockBalance,
    sortBy,
    setSortBy,
    orderBy,
    setOrderBy,
    loadingStockBalance,
  } = inventoriesStore;
  const [active, setActive] = React.useState<string | null>(null);
  const skeletonCardArray = Array(6).fill("");

  const handleSortByClick = (value: Extract<keyof StockBalance, string>) => {
    if (active === value) {
      setActive(null);
      setSortBy(null);
    } else {
      setActive(value);
      setSortBy(value);
    }
  };

  const handleOrderBy = () => {
    setOrderBy(orderBy === "asc" ? "desc" : "asc");
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography variant="body1" component="h2">
            Sort by
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            {sortBy.map((value) => (
              <Grid item key={value.id}>
                <Chip
                  label={value.label}
                  clickable
                  color={value.id === active ? "primary" : "default"}
                  onClick={() => handleSortByClick(value.id)}
                />
              </Grid>
            ))}
            <Grid item>
              <Chip
                icon={<ImportExportIcon />}
                label={orderBy === "asc" ? "Ascending" : "Descending"}
                clickable
                variant="outlined"
                color={orderBy === "asc" ? "primary" : "secondary"}
                onClick={() => handleOrderBy()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.paper}>
        <Grid container spacing={1}>
          {loadingStockBalance ? (
            skeletonCardArray.map((item, index) => (
              <Grid key={index} item md={4} sm={6} xs={6}>
                <ReportStockBalanceCardSkeleton />
              </Grid>
            ))
          ) : stockBalance.length === 0 ? (
            <Grid item xs={12}>
              <Typography variant="h5">No Data.</Typography>
            </Grid>
          ) : (
            stockBalance.map((item) => (
              <Grid key={item.itemCode} item md={4} sm={6} xs={6}>
                <ReportStockBalanceCard item={item} />
              </Grid>
            ))
          )}
        </Grid>
      </div>
    </>
  );
});
