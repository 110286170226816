import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import {observer} from "mobx-react-lite";
import React from "react";
import {useStore} from "../../app/stores/store";

interface Props {
  id: string;
}

export default observer(function ItemDeleteDialog({id}: Props) {
  const {dialogStore} = useStore();
  const {closeDialog, dialog} = dialogStore;

  const handleClick = () => {
    closeDialog();
    if (dialog.actionDelete) dialog.actionDelete(id);
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Delete Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure want to delete this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClick} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </>
  );
});
