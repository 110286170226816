import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import {
  CashFlow,
  CashFlowFormValues,
  CashFlowListSearch,
  CashFlowType,
} from "../models/cashFlow";
import { HeadCell } from "../models/table";

export default class CashFlowStore {
  documentRegistry = new Map<string, CashFlow>();
  headCells: HeadCell<any>[] = [];
  cashFlowTypes: CashFlowType[] = [];
  loadingCashflow = false;
  open = false;
  totalDynamicRow: number[] = [];
  totalBalance: number = 0;
  predicate = new Map<string, string>();

  constructor() {
    makeAutoObservable(this);
  }

  setOpen = (open: boolean) => {
    this.open = open;
  };

  setPredicate = (search: CashFlowListSearch) => {
    try {
      this.predicate.clear();
      this.predicate.set(
        "startDate",
        search.startDate.toLocaleDateString("EN-US")
      );
      this.predicate.set("endDate", search.endDate.toLocaleDateString("EN-US"));
      this.predicate.set("id", search.bankAccountId);
    } catch (error) {
      console.log(error);
    }
  };

  loadDocuments = async (
    startDate: Date,
    endDate: Date,
    bankAccountId: string
  ) => {
    this.loadingCashflow = true;

    try {
      const params = new URLSearchParams();
      params.append("startDate", startDate.toLocaleDateString("EN-US"));
      params.append("endDate", endDate.toLocaleDateString("EN-US"));
      params.append("id", bankAccountId);

      const result = await agent.Cashflows.list(params);
      const cashFlowTypes = await agent.Cashflows.cashflowType();
      runInAction(() => {
        this.documentRegistry.clear();
        this.cashFlowTypes = cashFlowTypes;
        result.forEach((item) => {
          this.documentRegistry.set(item.id, item);
        });
        this.setDocumentHeader();
        this.calculateTotal();
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingCashflow = false));
    }
  };

  loadDocument = async (id: string) => {
    this.loadingCashflow = true;

    try {
      const result = await agent.Cashflows.details(id);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingCashflow = false));
    }
  };

  get documentList() {
    return Array.from(this.documentRegistry.values());
  }

  loadCashflowType = async () => {
    this.loadingCashflow = true;

    try {
      const result = await agent.Cashflows.cashflowType();
      runInAction(() => {
        this.cashFlowTypes = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingCashflow = false));
    }
  };

  private setDocumentHeader() {
    this.headCells = [
      {
        id: "date",
        align: "left",
        disablePadding: false,
        label: "Date",
        disableSort: false,
        skeletonShape: "text",
      },
      {
        id: "store",
        align: "left",
        disablePadding: false,
        label: "Store",
        disableSort: false,
        skeletonShape: "text",
      },
      {
        id: "documentNo",
        align: "left",
        disablePadding: false,
        label: "Document No.",
        disableSort: false,
        skeletonShape: "text",
      },
      {
        id: "description",
        align: "left",
        disablePadding: false,
        label: "Description",
        disableSort: true,
        skeletonShape: "text",
      },
    ];
    this.cashFlowTypes.forEach((type) => {
      this.headCells.push({
        id: type.description,
        align: "right",
        disablePadding: false,
        label: type.description,
        disableSort: true,
        skeletonShape: "text",
      });
    });
    this.headCells.push({
      id: "balance",
      align: "right",
      disablePadding: false,
      label: "Balance",
      disableSort: true,
      skeletonShape: "text",
    });
  }

  createCashflow = async (document: CashFlowFormValues) => {
    try {
      await agent.Cashflows.create(document);
      return "Create cash in document is success!";
    } catch (error) {
      throw error;
    }
  };

  updateCashflow = async (document: CashFlowFormValues) => {
    try {
      await agent.Cashflows.update(document).then((document) => {
        runInAction(() => {
          this.documentRegistry.set(document.id, document);
          this.calculateTotal();
        });
      });
      return "Update cash in document is success!";
    } catch (error) {
      throw error;
    }
  };

  deleteCashflow = async (ids: string[]) => {
    this.loadingCashflow = true;

    try {
      await agent.Cashflows.delete(ids).then(() => {
        runInAction(() => {
          ids.forEach((x) => {
            this.documentRegistry.delete(x);
          });
          this.calculateTotal();
        });
      });
      return "Delete cash in(s) success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingCashflow = false));
    }
  };

  postCashflow = async (id: string) => {
    this.loadingCashflow = true;

    try {
      await agent.Cashflows.post(id).then(() => {
        runInAction(() => {
          const document = this.documentRegistry.get(id);
          if (document) {
            document.posted = !document.posted;
            this.documentRegistry.set(id, document);
          }
        });
      });
      return "Document is posted!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingCashflow = false));
    }
  };

  private calculateTotal() {
    this.totalDynamicRow = [];
    this.cashFlowTypes.forEach((type) => {
      const total = this.documentList
        .filter((x) => x.cashFlowType?.id === type.id)
        .reduce(
          (total, currentData) => (total = total + currentData.amount),
          0
        );
      this.totalDynamicRow.push(total);
    });
    this.totalBalance = this.documentList.reduce(
      (total, currentData) => (total = total + currentData.balance),
      0
    );
  }
}
