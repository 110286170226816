import InputAdornment from "@material-ui/core/InputAdornment";
import TextField, { BaseTextFieldProps } from "@material-ui/core/TextField";
import { useField } from "formik";
import React from "react";
import NumberFormat from "react-number-format";

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat<unknown> | null) => void;
  onChange: (event: {
    target: { name: string; value: number | undefined };
  }) => void;
  name: string;
}

function PriceFormat(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      thousandSeparator
      decimalScale={2}
    />
  );
}

function QtyFormat(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      thousandSeparator
      decimalScale={0}
      fixedDecimalScale={false}
    />
  );
}

interface Props extends BaseTextFieldProps {
  placeholder?: string;
  name: string;
  type?: string;
  label?: string;
  helperText?: string;
  onChange?: (e: React.ChangeEvent<{ value: unknown }>) => void;
  andorment?: string;
}

export default function PriceInputField(props: Props) {
  const [field, meta, helpers] = useField(props.name);

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    helpers.setValue(e.target.value);
    if (props.onChange !== undefined) {
      props.onChange(e);
    }
  };

  return (
    <TextField
      {...field}
      {...props}
      id={props.name}
      error={meta.touched && !!meta.error}
      fullWidth
      InputProps={{
        startAdornment: <InputAdornment position="start">IDR</InputAdornment>,
        inputComponent: PriceFormat as any,
      }}
      onChange={(e) => handleChange(e)}
      color="primary"
    />
  );
}

export function QtyInputField(props: Props) {
  const [field, meta, helpers] = useField(props.name);

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    helpers.setValue(e.target.value);
    if (props.onChange !== undefined) {
      props.onChange(e);
    }
  };

  return (
    <TextField
      {...field}
      {...props}
      id={props.name}
      error={meta.touched && !!meta.error}
      fullWidth
      InputProps={
        props.andorment
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  {props.andorment}
                </InputAdornment>
              ),
              inputComponent: QtyFormat as any,
            }
          : {
              inputComponent: QtyFormat as any,
            }
      }
      color="primary"
      onChange={(e) => handleChange(e)}
    />
  );
}
