import {
  Popper,
  Grow,
  Paper,
  Button,
  Grid,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Divider,
  ClickAwayListener,
} from "@material-ui/core";
import React from "react";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useStyles } from "../../app/layout/style";
import { DatePicker } from "@material-ui/pickers";
import TodayIcon from "@material-ui/icons/Today";
import SearchIcon from "@material-ui/icons/Search";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";

const useLocalStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
  })
);

export default observer(function Filter() {
  const classes = useStyles();
  const useLocalClasses = useLocalStyles();
  const { dashboardStore } = useStore();
  const { filterFormat, filterValue, setFilterValue, setDate } = dashboardStore;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleDateChange = (date: any) => {
    setFilterValue(new Date(date));
  };

  const handleSearch = () => {
    setDate(filterValue);
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div ref={anchorRef} className={useLocalClasses.root}>
        <Button color="primary" size="small" onClick={handleToggle}>
          Filter <FilterListIcon />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-end" ? "right top" : "right bottom",
              }}
            >
              <Paper className={classes.form}>
                <Typography variant="subtitle2">Filter</Typography>
                <Divider />
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <TodayIcon />
                  </Grid>
                  <Grid item>
                    <DatePicker
                      format={filterFormat}
                      inputVariant="outlined"
                      margin="dense"
                      label="Value"
                      value={filterValue}
                      onChange={handleDateChange}
                      autoOk
                      disableToolbar
                      variant="inline"
                      disableFuture
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleSearch}
                    >
                      <SearchIcon />
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
});
