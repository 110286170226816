import { Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import SalesRevenue from "./SalesRevenue";
import Payment from "./Payment";
import Revenue from "./Revenue";
import StockBalance from "./StockBalance";
import SalesTrend from "./SalesTrend";
import Filter from "./Filter";
import { useStore } from "../../app/stores/store";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";
import { Helmet } from "react-helmet";

export default observer(function Dashboard() {
  const { dashboardStore } = useStore();
  const { date, loadingRevenue } = dashboardStore;

  return (
    <>
      <Helmet>
        <title>KEDJORA BOUTIQUE</title>
      </Helmet>
      <Grid container spacing={1}>
        <Grid item lg={7} md={12} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h6" component="h2">
                    Dashboard
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2" component="h2">
                    {loadingRevenue ? (
                      <Skeleton variant="text" width={100} />
                    ) : (
                      moment(date).format("DD-MMM-yyyy")
                    )}
                  </Typography>
                </Grid>
                <Grid item>
                  <Filter />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Revenue />
            </Grid>
            <Grid item xs={12}>
              <SalesRevenue />
            </Grid>
            <Grid item xs={12}>
              <Payment />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={5} md={12} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <StockBalance />
            </Grid>
            <Grid item xs={12}>
              <SalesTrend />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});
