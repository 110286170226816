import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import { useStyles } from "../../app/layout/style";
import { Size } from "../../app/models/item";

interface Props {
  options?: Size[];
  value: Size | null;
  setValue: (value: Size | null) => void;
  optionDisabled?: Size[];
  loading: boolean;
}

export default function ItemSizeAutoComplete({
  options,
  value,
  setValue,
  optionDisabled,
  loading,
}: Props) {
  const classes = useStyles();
  const handleOptionDisabled = (group: Size) => {
    if (optionDisabled) return optionDisabled.some((x) => x.id === group.id);
    else return false;
  };

  return (
    <FormControl fullWidth className={classes.icon}>
      <Autocomplete
        id="search-groups"
        options={options ? options : []}
        getOptionLabel={(option) => option.description}
        getOptionSelected={(option, value) =>
          option.description === value.description
        }
        getOptionDisabled={(option) => handleOptionDisabled(option)}
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue !== "string") {
            setValue(newValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Size"
            placeholder="Please select any size."
            margin="dense"
          />
        )}
        disabled={loading}
      />
    </FormControl>
  );
}
