import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import AddIcon from "@material-ui/icons/Add";
import { TablePagination, useMediaQuery, useTheme } from "@material-ui/core";
import ItemCard from "./ItemCard";
import ItemDeleteDialog from "./ItemDeleteDialog";
import ItemCardSkeleton from "./ItemCardSkeleton";

export default observer(function ItemCardList() {
  const classes = useStyles();
  const theme = useTheme();
  const widthSizeMatch = useMediaQuery(theme.breakpoints.up("xl"));
  const { itemStore, dialogStore, snackbarStore } = useStore();
  const {
    pageNumber,
    pageSize,
    deleteItem,
    itemList,
    loadingItem,
    setPageNumber,
    setPageSize,
    pagination,
    rowsOptions,
    setRowsOptions,
  } = itemStore;
  const { openSnackbar } = snackbarStore;
  const { openDialogDelete, closeDialog } = dialogStore;
  const skeletonCardArray = Array(widthSizeMatch ? 8 : 6).fill("");

  useEffect(() => {
    if (itemList.length === 0) {
      if (widthSizeMatch) {
        setRowsOptions([8, 8 * 2, 8 * 4, 8 * 8]);
        setPageSize(8);
      } else {
        setRowsOptions([6, 6 * 2, 6 * 4, 6 * 8]);
        setPageSize(6);
      }
    }
  }, [itemList.length, widthSizeMatch, setRowsOptions, setPageSize]);

  const handleDeleteItem = (id: string) => {
    openDialogDelete(<ItemDeleteDialog id={id} />, handleDelete);
  };

  const handleDelete = (id: string) => {
    deleteItem(id)
      .catch((error) => {
        closeDialog();
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) openSnackbar(message, "success");
        closeDialog();
      });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.paperCard}>
            <Toolbar>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                {loadingItem ? (
                  <Skeleton variant="text" width="30%" />
                ) : (
                  "Item List"
                )}
              </Typography>
              {loadingItem ? (
                <Skeleton variant="circle" width={30} height={30} />
              ) : (
                <Tooltip title="Create">
                  <IconButton
                    component={Link}
                    to={"/items/form"}
                    color="primary"
                    size="medium"
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Toolbar>
          </Paper>
        </Grid>
        {loadingItem
          ? skeletonCardArray.map((item, index) => (
              <Grid key={index} item xl={3} lg={4} md={6} sm={12} xs={12}>
                <ItemCardSkeleton />
              </Grid>
            ))
          : itemList.map(([id, item]) => (
              <Grid key={id} item xl={3} lg={4} md={6} sm={12} xs={12}>
                <ItemCard item={item} deleteItem={handleDeleteItem} />
              </Grid>
            ))}
        <Grid item xs={12}>
          <Paper className={classes.transferList}>
            <TablePagination
              rowsPerPageOptions={rowsOptions}
              component="div"
              count={pagination === null ? 0 : pagination.totalItems}
              page={pageNumber}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
});
