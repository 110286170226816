import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { observer } from "mobx-react-lite";
import React from "react";
import EnhancedTableHead from "../../app/common/table/EnhancedTableHead";
import EnhancedTableSkeleton from "../../app/common/table/EnhancedTableSkeleton";
import EnhancedTableToolbar from "../../app/common/table/EnhancedTableToolbar";
import DeleteDialog from "../../app/layout/DeleteDialog";
import { useStyles } from "../../app/layout/style";
import { Sale } from "../../app/models/sale";
import { useStore } from "../../app/stores/store";
import InvoiceTableRow from "./InvoiceTableRow";

export default observer(function StockOutgoingTable() {
  const classes = useStyles();
  const { salesStore, tableStore, snackbarStore, dialogStore, userStore } =
    useStore();
  const {
    pageNumber,
    pageSize,
    documentList,
    headCells,
    loadingInvoiceList,
    paymentTypes,
    deleteSales,
    setPageNumber,
    setPageSize,
    pagination,
    setSortBy,
    sortBy,
    orderBy,
    rowsOptions,
    open,
  } = salesStore;
  const { selectedItem, setSelectedItem, selectItem, isSelected } = tableStore;
  const { user } = userStore;
  const { openSnackbar } = snackbarStore;
  const { openDialog, closeDialog } = dialogStore;
  const role = user?.roles.find((x) => x.name === "Developer");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = sortBy === property && orderBy === "asc";
    setSortBy(property as keyof Sale, isAsc ? "desc" : "asc");
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (selectedItem.length === 0) {
        const newSelecteds = role
          ? documentList.map((document) => document.id)
          : documentList
              .filter(
                (x) =>
                  new Date(x.date).setHours(0, 0, 0, 0) >=
                  new Date().setHours(0, 0, 0, 0)
              )
              .map((document) => document.id);
        setSelectedItem(newSelecteds);
        return;
      }
    }
    setSelectedItem([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    selectItem(id);
  };

  const handleClickDelete = (event: React.MouseEvent<unknown>) => {
    openDialog(
      <DeleteDialog title={"Are you sure want to delete this document(s)"} />,
      "xs",
      handleDelete
    );
  };

  const handleDelete = () => {
    deleteSales(selectedItem)
      .catch((error) => {
        setSelectedItem([]);
        closeDialog();
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) openSnackbar(message, "success");
        setSelectedItem([]);
        closeDialog();
      });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  return (
    <div className={classes.root}>
      {open && (
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            title="Invoice List"
            numSelected={selectedItem.length}
            handleDelete={handleClickDelete}
            loading={loadingInvoiceList}
          />
          <TableContainer>
            <Table className={classes.table} size="medium" component="table">
              <EnhancedTableHead
                headCells={headCells}
                numSelected={selectedItem.length}
                order={orderBy}
                orderBy={sortBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={documentList.length}
                loading={loadingInvoiceList}
              />
              <TableBody>
                {loadingInvoiceList ? (
                  <EnhancedTableSkeleton row={6} cellArray={headCells} />
                ) : documentList.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={headCells.length + 2} align="center">
                      No Data.
                    </TableCell>
                  </TableRow>
                ) : (
                  documentList.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <InvoiceTableRow
                        key={index}
                        headCells={headCells}
                        row={row}
                        dynamicRow={paymentTypes}
                        labelId={labelId}
                        isItemSelected={isItemSelected}
                        handleClick={handleClick}
                      />
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={rowsOptions}
            component="div"
            count={pagination === null ? 0 : pagination.totalItems}
            rowsPerPage={pageSize}
            page={pageNumber}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </div>
  );
});
