import { Card, CardContent, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStore } from "../../app/stores/store";

export default observer(function Revenue() {
  const { dashboardStore } = useStore();
  const { loadRevenue, loadingRevenue, revenue } = dashboardStore;

  useEffect(() => {
    loadRevenue();
  }, [loadRevenue]);

  return (
    <Grid container direction="row" alignItems="stretch" spacing={1}>
      <Grid item lg={4} md={4} xs={6}>
        <Card>
          <CardContent>
            <Typography
              variant="body1"
              color="primary"
              style={{ fontWeight: "bold" }}
              component="h6"
            >
              {loadingRevenue ? (
                <Skeleton variant="text" />
              ) : (
                `IDR ${revenue?.daily.toLocaleString()}`
              )}
            </Typography>
            <Typography variant="caption">Daily Revenue</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={4} md={4} xs={6}>
        <Card>
          <CardContent>
            <Typography
              variant="body1"
              color="primary"
              style={{ fontWeight: "bold" }}
              component="h2"
            >
              {loadingRevenue ? (
                <Skeleton variant="text" />
              ) : (
                `IDR ${revenue?.weekly.toLocaleString()}`
              )}
            </Typography>
            <Typography variant="caption">Weekly Revenue</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={4} md={4} xs={12}>
        <Card>
          <CardContent>
            <Typography
              variant="body1"
              color="primary"
              style={{ fontWeight: "bold" }}
              component="h2"
            >
              {loadingRevenue ? (
                <Skeleton variant="text" />
              ) : (
                `IDR ${revenue?.monthly.toLocaleString()}`
              )}
            </Typography>
            <Typography variant="caption">Monthly Revenue</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
});
