import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import InputField from "../../app/common/form/InputField";
import { useStyles } from "../../app/layout/style";
import { StockLocationFormValues } from "../../app/models/location";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import { useStore } from "../../app/stores/store";
import MySwitch from "../../app/common/form/MySwitch";

export default observer(function StockLocationForm() {
  const classes = useStyles();
  const { stockLocationStore, snackbarStore } = useStore();
  const {
    createStockLocation,
    loadStockLocation,
    updateStockLocation,
    loadingStockLocation,
  } = stockLocationStore;
  const { openSnackbar } = snackbarStore;
  const [stockLocation, setStockLocation] = useState<StockLocationFormValues>(
    new StockLocationFormValues()
  );
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      loadStockLocation(id).then((location) => {
        setStockLocation(new StockLocationFormValues(location));
      });
    }
  }, [loadStockLocation, id]);

  const schema = Yup.object({
    name: Yup.string().required("Stock Location is required."),
    city: Yup.string().required("City is required."),
  });

  const handleFormSubmit = (
    location: StockLocationFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (!stockLocation.id) {
      let newLocation = {
        ...location,
        id: uuid(),
      };
      createStockLocation(newLocation)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            setStockLocation(new StockLocationFormValues());
            resetForm();
          }
        });
    } else {
      updateStockLocation(location)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            history.push("/stocklocation");
          }
        });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Paper className={classes.form}>
          <Typography variant="h5">Form {id ? "Update" : "Create"}</Typography>
          <Divider />
          <Formik
            validationSchema={schema}
            enableReinitialize
            initialValues={stockLocation}
            onSubmit={(values, { resetForm, setSubmitting }) =>
              handleFormSubmit(values, resetForm, setSubmitting)
            }
          >
            {({ handleSubmit, isSubmitting, isValid, dirty }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <InputField
                  variant="standard"
                  label="Stock Location"
                  name="name"
                  placeholder="Please input stock location name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={isSubmitting || loadingStockLocation}
                />
                <InputField
                  variant="standard"
                  label="City"
                  name="city"
                  placeholder="Please input city"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={isSubmitting || loadingStockLocation}
                />
                {id && <MySwitch name="isActive" label="Active Location" />}
                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="stretch"
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      {isSubmitting && (
                        <CircularProgress
                          className={classes.progress}
                          size={16}
                          color="inherit"
                        />
                      )}
                      Save
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="default"
                      component={Link}
                      to="/stocklocation"
                      disabled={isSubmitting || loadingStockLocation}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  );
});
