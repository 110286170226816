import Grid from "@material-ui/core/Grid";
import {Form, Formik} from "formik";
import {observer} from "mobx-react-lite";
import React from "react";
import SearchInput from "../../app/common/form/SearchInput";
import {useStore} from "../../app/stores/store";
import UserCardList from "./UserCardList";

export default observer(function UserList() {
  const {userStore} = useStore();
  const {setPredicate} = userStore;
  const [search, setSearch] = React.useState({search: ""});

  const handleSearchClick = (values: any) => {
    setPredicate(values.search);
    setSearch({search: values.search});
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item lg={4} md={6} xs={12}>
          <Formik
            enableReinitialize
            initialValues={search}
            onSubmit={(values) => handleSearchClick(values)}
          >
            {({handleSubmit}) => (
              <Form
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <SearchInput placeholder="Search..." name="search" autoFocus />
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      <UserCardList />
    </>
  );
});
