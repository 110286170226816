import { Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import SearchInput from "../../app/common/form/SearchInput";
import { useStore } from "../../app/stores/store";
import StoreCardList from "./StoreCardList";

export default observer(function StoreList() {
  const { storeStore } = useStore();
  const { storeRegistry, loadStores, setPredicate } = storeStore;
  const [search, setSearch] = React.useState({ search: "" });

  useEffect(() => {
    if (storeRegistry.size <= 1) {
      setPredicate(search.search);
      loadStores();
    }
  }, [storeRegistry, loadStores, setPredicate, search.search]);

  const handleSearchClick = (values: any) => {
    setPredicate(values.search);
    setSearch({ search: values.search });
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item lg={4} md={6} xs={12}>
          <Formik
            enableReinitialize
            initialValues={search}
            onSubmit={(values) => handleSearchClick(values)}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <SearchInput placeholder="Search..." name="search" autoFocus />
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      <StoreCardList />
    </>
  );
});
