import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";
import {useStyles} from "../../layout/style";
import {HeadCell, Order} from "../../models/table";
import {observer} from "mobx-react-lite";
import Skeleton from "@material-ui/lab/Skeleton";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";

interface EnhancedTableProps<T> {
  headCells: HeadCell<T>[];
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  loading: boolean;
}

export default observer(function EnhancedTableHead(props: EnhancedTableProps<any>) {
  const classes = useStyles();
  const {
    headCells,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    loading = true,
  } = props;

  if (loading) {
    return (
      <TableHead>
        <TableRow>
          <TableCell style={{padding: "16px"}} padding="checkbox">
            <Skeleton variant="rect" width="100%" />
          </TableCell>
          <TableCell style={{padding: "16px"}} padding="checkbox">
            <Skeleton variant="rect" width="100%" />
          </TableCell>
          {headCells.map((cell, index) => (
            <TableCell
              key={index}
              style={{padding: "16px"}}
              padding={cell.skeletonShape === "rect" ? "checkbox" : "normal"}
            >
              <Skeleton
                variant={cell.skeletonShape}
                width={cell.skeletonShape === "rect" ? "100%" : "50%"}
              />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) =>
          headCell.label === "" ? (
            <TableCell key={headCell.id} padding="checkbox"></TableCell>
          ) : (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.disableSort ? (
                headCell.label.toLocaleUpperCase()
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={(e) => onRequestSort(e, headCell.id)}
                >
                  {headCell.label.toLocaleUpperCase()}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
});

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  })
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
