import { Grid, Button } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { usePosStyles } from "../../app/layout/pos";
import ClearIcon from "@material-ui/icons/Clear";
import SaveIcon from "@material-ui/icons/Save";
import PaymentIcon from "@material-ui/icons/Payment";

interface Props {
  id: string;
  loading: boolean;
  handleFormSave: (submitForm: () => void) => void;
  submitForm: () => void;
  handleFormClear: () => void;
}

export default observer(function POSButton(props: Props) {
  const classes = usePosStyles();
  const { id, loading, handleFormSave, submitForm, handleFormClear } = props;

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.save}
            disabled={loading}
            onClick={() => handleFormSave(submitForm)}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.save}
            disabled={loading || id !== undefined}
            onClick={handleFormClear}
            startIcon={<ClearIcon />}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
      <Button
        fullWidth
        type="submit"
        variant="contained"
        color="secondary"
        className={classes.pay}
        disabled={loading}
        startIcon={<PaymentIcon />}
      >
        Pay
      </Button>
    </>
  );
});
