import { TableCell, TableRow } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import { observer } from "mobx-react-lite";
import React from "react";
import EnhancedTableHead, {
  StyledTableCell,
  StyledTableRow,
} from "../../app/common/table/EnhancedTableHead";
import EnhancedTableSkeleton from "../../app/common/table/EnhancedTableSkeleton";
import EnhancedTableToolbar from "../../app/common/table/EnhancedTableToolbar";
import DeleteDialog from "../../app/layout/DeleteDialog";
import { useStyles } from "../../app/layout/style";
import { CashFlow } from "../../app/models/cashFlow";
import { Order } from "../../app/models/table";
import { useStore } from "../../app/stores/store";
import CashFlowTableRow from "./CashFlowTableRow";

interface Props {
  open: boolean;
}

export default observer(function CashFlowTable({ open }: Props) {
  const classes = useStyles();
  const { cashFlowStore, tableStore, snackbarStore, dialogStore } = useStore();
  const {
    documentList,
    cashFlowTypes,
    loadingCashflow,
    deleteCashflow,
    headCells,
    totalBalance,
    totalDynamicRow,
  } = cashFlowStore;
  const {
    stableSort,
    getComparator,
    selectedItem,
    setSelectedItem,
    selectItem,
    isSelected,
  } = tableStore;
  const { openSnackbar } = snackbarStore;
  const { openDialog, closeDialog } = dialogStore;
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof CashFlow>("date");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property as keyof CashFlow);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (selectedItem.length === 0) {
        const newSelecteds = documentList
          .filter((x) => x.canChange)
          .map((document) => document.id);
        setSelectedItem(newSelecteds);
        return;
      }
    }
    setSelectedItem([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    selectItem(id);
  };

  const handleClickDelete = (event: React.MouseEvent<unknown>) => {
    openDialog(
      <DeleteDialog title={"Are you sure want to delete this document(s)"} />,
      "xs",
      handleDelete
    );
  };

  const handleDelete = () => {
    deleteCashflow(selectedItem)
      .catch((error) => {
        setSelectedItem([]);
        closeDialog();
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) openSnackbar(message, "success");
        setSelectedItem([]);
        closeDialog();
      });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          title="Cash Flow List"
          numSelected={selectedItem.length}
          handleDelete={handleClickDelete}
          linkAddNew="/cashflow/form"
          loading={loadingCashflow}
        />
        {open && (
          <TableContainer>
            <Table className={classes.table} size="medium" component="table">
              <EnhancedTableHead
                headCells={headCells}
                numSelected={selectedItem.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={documentList.length}
                loading={loadingCashflow}
              />
              <TableBody>
                {loadingCashflow ? (
                  <EnhancedTableSkeleton row={6} cellArray={headCells} />
                ) : documentList.length !== 0 ? (
                  <>
                    {stableSort(
                      documentList,
                      getComparator(order, orderBy)
                    ).map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <CashFlowTableRow
                          key={index}
                          row={row}
                          dynamicRow={cashFlowTypes}
                          labelId={labelId}
                          isItemSelected={isItemSelected}
                          handleClick={handleClick}
                        />
                      );
                    })}
                    <StyledTableRow>
                      <StyledTableCell
                        align="right"
                        colSpan={6}
                      ></StyledTableCell>
                      {totalDynamicRow.map((total, index) => (
                        <StyledTableCell key={index} align="right">
                          {total.toLocaleString()}
                        </StyledTableCell>
                      ))}
                      <StyledTableCell align="right">
                        {totalBalance.toLocaleString()}
                      </StyledTableCell>
                    </StyledTableRow>
                  </>
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={headCells.length + 2}>
                      No Data.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </div>
  );
});
