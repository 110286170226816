import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import {
  Color,
  Item,
  ItemCategory,
  ItemDropdown,
  ItemFormValues,
  Unit,
} from "../models/item";
import { PaginatedResult, Pagination } from "../models/pagination";
import { Order, SortBy } from "../models/table";

export default class ItemStore {
  itemsRegistry = new Map<string, Item>();
  itemsDropDown: ItemDropdown[] = [];
  itemsSearch: ItemDropdown[] = [];
  units: Unit[] = [];
  itemCategories: ItemCategory[] = [];
  colors: Color[] = [];
  loadingItem = false;
  predicate = new Map<string, string>();
  rowsOptions = [6];
  pagination: Pagination | null = null;
  sortBy: SortBy<Item>[] = [
    { id: "color", label: "Color" },
    { id: "fullCode", label: "Item Code" },
    { id: "itemCategory", label: "Item Category" },
    { id: "unit", label: "Unit" },
  ];
  orderBy: Order = "asc";

  constructor() {
    makeAutoObservable(this);
    this.predicate.set("pageNumber", "1");
    this.predicate.set("orderBy", this.orderBy);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadItems();
      }
    );
  }

  setPredicate = (value: string) => {
    this.predicate.delete("search");
    this.predicate.set("search", value);
    this.predicate.delete("pageNumber");
    this.predicate.set("pageNumber", "1");
  };

  setPageNumber = (value: number) => {
    this.predicate.delete("pageNumber");
    this.predicate.set("pageNumber", value.toString());
  };

  setPageSize = (value: number) => {
    this.predicate.delete("pageSize");
    this.predicate.set("pageSize", value.toString());
    // this.predicate.set("pageNumber", "1");
  };

  setSortBy = (value: Extract<keyof Item, string> | null) => {
    this.predicate.delete("sortBy");
    if (value !== null) this.predicate.set("sortBy", value.toString());
  };

  setOrderBy = (value: Order) => {
    this.predicate.delete("orderBy");
    this.predicate.set("orderBy", value.toString());
    this.orderBy = value;
  };

  setRowsOptions = (value: number[]) => {
    this.rowsOptions = value;
  };

  get axiosParams() {
    const params = new URLSearchParams();
    this.predicate.forEach((value, key) => {
      params.delete(key);
      params.append(key, value);
    });
    return params;
  }

  get pageNumber() {
    return Number(this.predicate.get("pageNumber")) - 1;
  }

  get pageSize() {
    return Number(this.predicate.get("pageSize"));
  }

  private loadItemsProcess = (result: PaginatedResult<Item[]>) => {
    runInAction(() => {
      this.itemsRegistry.clear();
      result.data.forEach((item) => {
        this.itemsRegistry.set(item.id, item);
      });
      this.pagination = result.pagination;
    });
  };

  loadItems = async () => {
    this.loadingItem = true;

    try {
      const result = await agent.Items.list(this.axiosParams);
      this.loadItemsProcess(result);
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingItem = false));
    }
  };

  get itemList() {
    return Array.from(this.itemsRegistry);
  }

  loadItem = async (id: string) => {
    this.loadingItem = true;

    try {
      const result = await agent.Items.details(id);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingItem = false));
    }
  };

  loadUnits = async () => {
    this.loadingItem = true;

    try {
      const result = await agent.Items.loadUnits();
      runInAction(() => {
        this.units = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingItem = false));
    }
  };

  loadCategories = async () => {
    this.loadingItem = true;

    try {
      const result = await agent.Items.loadCategories();
      runInAction(() => {
        this.itemCategories = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingItem = false));
    }
  };

  loadColors = async () => {
    this.loadingItem = true;

    try {
      const result = await agent.Items.loadColors();
      runInAction(() => {
        this.colors = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingItem = false));
    }
  };

  loadDropDownItem = async () => {
    this.loadingItem = true;

    try {
      const dropdown = await agent.Items.dropdown();
      runInAction(() => {
        this.itemsDropDown = dropdown;
        this.itemsSearch = dropdown;
      });
      return dropdown;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingItem = false));
    }
  };

  createItem = async (item: ItemFormValues, file: any) => {
    try {
      let newItem = item;
      if (file) {
        const result = await agent.Photos.uploadPhoto(file);
        newItem = { ...item, imageId: result.data.id };
      }
      await agent.Items.create(newItem);
      const result = await agent.Items.list(this.axiosParams);
      this.loadItemsProcess(result);
      return "Create item success!";
    } catch (error) {
      throw error;
    }
  };

  updateItem = async (item: ItemFormValues, file: any) => {
    try {
      const oldImageId = item.imageId;
      let updatedItem = item;

      if (file) {
        const result = await agent.Photos.uploadPhoto(file);
        updatedItem = { ...item, imageId: result.data.id };
      }

      const resultUpdate = await agent.Items.update(updatedItem);
      runInAction(() => {
        this.itemsRegistry.set(resultUpdate.id, resultUpdate);
      });

      if (file) {
        if (oldImageId) {
          await agent.Photos.deletePhoto(oldImageId);
        }
      }
      return "Update item success!";
    } catch (error) {
      throw error;
    }
  };

  deleteItem = async (id: string) => {
    this.loadingItem = true;

    try {
      await agent.Items.delete(id);
      const result = await agent.Items.list(this.axiosParams);
      this.loadItemsProcess(result);
      return "Delete item success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingItem = false));
    }
  };

  searchItem = (search: string) => {
    if (search !== "")
      return this.itemsSearch.filter((x) =>
        x.itemCode.toLowerCase().includes(search.toLowerCase())
      );

    return this.itemsSearch;
  };
}
