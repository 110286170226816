import { Divider, Hidden, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { useStore } from "../../app/stores/store";
import InvoiceTable from "./InvoiceTable";
import POSSlip from "./POSSlip";
import InvoiceFilter from "./InvoiceFilter";
import { Helmet } from "react-helmet";
import InvoiceCardList from "./InvoiceCardList";

export default observer(function InvoiceList() {
  const { storeStore, salesStore } = useStore();
  const { loadStoresGranted } = storeStore;
  const { loadPaymentType, paymentTypes } = salesStore;
  const componentRef = useRef(null);

  useEffect(() => {
    loadStoresGranted();
    if (paymentTypes.length === 0) loadPaymentType();
  }, [paymentTypes.length, loadStoresGranted, loadPaymentType]);

  return (
    <div>
      <Helmet>
        <title>KEDJORA BOUTIQUE - Invoice List</title>
      </Helmet>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">Invoice List</Typography>
        </Grid>
        <Grid item>
          <InvoiceFilter />
        </Grid>
      </Grid>
      <Divider />
      <Hidden lgUp implementation="css">
        <InvoiceCardList />
      </Hidden>
      <Hidden mdDown implementation="css">
        <InvoiceTable />
      </Hidden>
      <div style={{ display: "none" }}>
        <POSSlip componentToPrint={componentRef} />
      </div>
    </div>
  );
});
