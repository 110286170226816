import { Container, makeStyles, Paper, Theme, useMediaQuery } from "@material-ui/core";
import Copyright from "./Copyright";

type StyleProps = {
  prefersDarkMode: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  footer: {
    padding: theme.spacing(3, 0),
    backgroundColor: ({ prefersDarkMode }) =>
    prefersDarkMode ? "#424242" : "#eeeeee",
  },
}));

export default function Footer() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const classes = useStyles({ prefersDarkMode });

  return (
    <Paper elevation={3} square className={classes.footer}>
      <Container maxWidth="xl">
        <Copyright />
      </Container>
    </Paper>
  );
}
