import { Chip, Hidden, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import SearchInput from "../../app/common/form/SearchInput";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import { useStore } from "../../app/stores/store";
import StockControlCardList from "./StockControlCardList";
import StockControlTable from "./StockControlTable";
import { StockControl } from "../../app/models/stockControl";

export default observer(function StockControlList() {
  const { stockControlStore } = useStore();
  const {
    setPredicate,
    predicate,
    setSortByCard,
    sortByCard,
    orderBy,
    setOrderBy,
  } = stockControlStore;
  const [active, setActive] = React.useState<string | null>(null);
  const [search, setSearch] = React.useState({
    search:
      predicate.get("search") === undefined ? "" : predicate.get("search"),
  });

  const handleSortByClick = (value: Extract<keyof StockControl, string>) => {
    if (active === value) {
      setActive(null);
      setSortByCard(null);
    } else {
      setActive(value);
      setSortByCard(value);
    }
  };

  const handleOrderBy = () => {
    setOrderBy(orderBy === "asc" ? "desc" : "asc");
  };

  const handleSearchClick = (values: any) => {
    setPredicate(values.search);
    setSearch({ search: values.search });
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item lg={6} md={6} xs={12}>
          <Hidden lgUp implementation="css">
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography variant="body2" component="span">
                  Sort by
                </Typography>
              </Grid>
              {sortByCard.map((value) => (
                <Grid item key={value.id}>
                  <Chip
                    label={value.label}
                    clickable
                    color={value.id === active ? "primary" : "default"}
                    onClick={() => handleSortByClick(value.id)}
                  />
                </Grid>
              ))}
              <Grid item>
                <Chip
                  icon={<ImportExportIcon />}
                  label={orderBy === "asc" ? "Ascending" : "Descending"}
                  clickable
                  variant="outlined"
                  color={orderBy === "asc" ? "primary" : "secondary"}
                  onClick={() => handleOrderBy()}
                />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Formik
            enableReinitialize
            initialValues={search}
            onSubmit={(values) => handleSearchClick(values)}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <SearchInput placeholder="Search..." name="search" autoFocus />
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Hidden lgUp implementation="css">
            <StockControlCardList />
          </Hidden>
          <Hidden mdDown implementation="css">
            <StockControlTable />
          </Hidden>
        </Grid>
      </Grid>
    </>
  );
});
