import { observer } from "mobx-react-lite";
import {
  StyledTableRow,
  StyledTableCell,
} from "../../app/common/table/EnhancedTableHead";
import { SalesPaymentChart } from "../../app/models/dashboard";

interface Props {
  row: SalesPaymentChart;
}

export default observer(function PaymentTableRow(props: Props) {
  const { row } = props;

  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row">
        {row.paymentType}
      </StyledTableCell>
      <StyledTableCell align="right">
        IDR {row.amount.toLocaleString()}
      </StyledTableCell>
    </StyledTableRow>
  );
});
