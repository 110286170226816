import { Typography, Divider, Grid, Hidden } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useStore } from "../../app/stores/store";
import ReportStockBalanceCardList from "./ReportStockBalanceCardList";
import ReportStockBalanceFilter from "./ReportStockBalanceFilter";
import ReportStockBalanceTable from "./ReportStockBalanceTable";

export default observer(function ReportStockBalance() {
  const { menuStore, inventoriesStore } = useStore();
  const { loadingMenu } = menuStore;
  const { stockBalance, loadStockBalance, sizes, loadSize } = inventoriesStore;

  useEffect(() => {
    if(sizes.length === 0) loadSize();
    if (stockBalance.length === 0) loadStockBalance();
  }, [loadSize, sizes.length, loadStockBalance, stockBalance.length]);

  return (
    <>
      {loadingMenu ? (
        <>
          <Typography variant="h4">
            <Skeleton width="50%" />
          </Typography>
          <Divider />
        </>
      ) : (
        <>
          <Helmet>
            <title>KEDJORA BOUTIQUE - Report Stock Balance</title>
          </Helmet>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h4">Report Stock Balance</Typography>
            </Grid>
            <Grid item>
              <ReportStockBalanceFilter />
            </Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Grid item xs={12}>
              <Hidden mdDown implementation="css">
                <ReportStockBalanceTable />
              </Hidden>
            </Grid>
          </Grid>
          <Hidden lgUp implementation="css">
            <ReportStockBalanceCardList />
          </Hidden>
        </>
      )}
    </>
  );
});
