import { Grid, Paper } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import DateInputField from "../../app/common/form/DateInputFIeld";
import Dropdown2 from "../../app/common/form/Dropdown2";
import InputField from "../../app/common/form/InputField";
import FaceIcon from "@material-ui/icons/Face";
import StoreIcon from "@material-ui/icons/Store";
import TodayIcon from "@material-ui/icons/Today";
import { usePosStyles } from "../../app/layout/pos";
import { useStore } from "../../app/stores/store";
import React from "react";

interface Props {
  isSubmitting: boolean;
}

export default observer(function POSTransaction(props: Props) {
  const classes = usePosStyles();
  const { isSubmitting } = props;
  const { salesStore, itemStore, storeStore, userStore } = useStore();
  const { loadingSales } = salesStore;
  const { loadingItem } = itemStore;
  const { storeDropDown, loadingStore } = storeStore;
  const { user } = userStore;
  const role = React.useMemo(
    () => user?.roles.find((x) => x.name === "Developer"),
    [user?.roles]
  );
  return (
    <Paper className={classes.transaction}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
          <TodayIcon />
        </Grid>
        <Grid item xs>
          <DateInputField
            label="Transaction Date"
            inputVariant="outlined"
            name="date"
            margin="dense"
            disabled={
              isSubmitting ||
              loadingItem ||
              loadingSales ||
              loadingStore ||
              role === undefined
            }
            maxDate={new Date()}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
          <StoreIcon />
        </Grid>
        <Grid item xs>
          <Dropdown2
            name="storeId"
            options={storeDropDown.map((x) => {
              return { text: x.name, value: x.id };
            })}
            label="Store"
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isSubmitting || loadingStore}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item>
          <FaceIcon />
        </Grid>
        <Grid item xs>
          <InputField
            margin="dense"
            variant="outlined"
            label="Customer Name"
            name="customerName"
            placeholder="Please input customer name."
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isSubmitting || loadingSales}
          />
        </Grid>
      </Grid>
      <InputField
        variant="outlined"
        label="Voucher"
        name="voucher"
        placeholder="Please input voucher no."
        margin="dense"
        InputLabelProps={{
          shrink: true,
        }}
        disabled={isSubmitting || loadingSales}
      />
    </Paper>
  );
});
