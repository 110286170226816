export interface User {
  id: string;
  userName: string;
  displayName: string;
  firstName: string;
  lastName: string;
  token: string;
  email: string;
  isActive: boolean;
  roles: Role[];
}

export interface Role {
  id: string;
  name: string;
}

export class Role implements Role {
  constructor(init?: RoleForm) {
    Object.assign(this, init);
  }
}

export interface RoleForm {
  name: string;
}

export class LoginFormValues {
  userName: string = "";
  password: string = "";
  rememberMe: boolean = false;

  constructor(user?: LoginFormValues) {
    if (user) {
      this.userName = user.userName;
      this.password = user.password;
      this.rememberMe = user.rememberMe;
    }
  }
}

export class UserFormValues {
  userName: string = "";
  password?: string = "";
  passwordConfirmation?: string = "";
  firstName: string = "";
  lastName?: string = "";
  email: string = "";
  isActive: boolean = true;
  roles: Role[] = [];

  constructor(user?: UserFormValues) {
    if (user) {
      this.userName = user.userName;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.email = user.email;
      this.isActive = user.isActive;
      this.roles = user.roles;
    }
  }
}

export class ProfileFormValues {
  userName: string = "";
  oldPassword?: string = "";
  password?: string = "";
  passwordConfirmation?: string = "";
  firstName: string = "";
  lastName?: string = "";
  email: string = "";

  constructor(user?: User) {
    if (user) {
      this.userName = user.userName;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.email = user.email;
    }
  }
}
