import { makeAutoObservable, reaction, runInAction } from "mobx";
import { history } from "../..";
import agent from "../api/agent";
import {
  LoginFormValues,
  ProfileFormValues,
  Role,
  RoleForm,
  User,
  UserFormValues,
} from "../models/user";
import { store } from "./store";

export default class UserStore {
  userRegistry = new Map<string, User>();
  user: User | null = null;
  roles: Role[] = [];
  loadingUser = false;
  loadingForm = false;
  predicate = new Map<string, string>();

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadUsers();
      }
    );
  }

  setPredicate = (value: string) => {
    this.predicate.delete("search");
    this.predicate.set("search", value);
  };

  get axiosParams() {
    const params = new URLSearchParams();
    if (this.predicate.size <= 1) {
      this.predicate.forEach((value, key) => {
        params.append(key, value);
      });
    }

    return params;
  }

  get isLoggedIn() {
    return !!this.user;
  }

  login = async (creds: LoginFormValues) => {
    try {
      const user = await agent.Account.login(creds);
      store.commonStore.setToken(user.token);
      let result = await agent.Menus.list();
      runInAction(() => {
        this.user = user;
        store.menuStore.menuRegistry.clear();
        result = store.menuStore.sortMenu(result);
        result.forEach((menu) => {
          store.menuStore.menuRegistry.set(menu.id, menu);
          store.menuStore.menus.push(menu);
          if (menu.childMenus)
            menu.childMenus = store.menuStore.sortMenu(menu.childMenus);
        });
      });
      history.push("/");
    } catch (error) {
      throw error;
    }
  };

  logout = () => {
    store.commonStore.setToken(null);
    window.localStorage.removeItem("jwt");
    this.user = null;
    store.menuStore.menuRegistry.clear();
    history.push("/");
  };

  getUser = async () => {
    try {
      const user = await agent.Account.current();
      let result = await agent.Menus.list();
      runInAction(() => {
        this.user = user;
        store.menuStore.menuRegistry.clear();
        result = store.menuStore.sortMenu(result);
        result.forEach((menu) => {
          store.menuStore.menuRegistry.set(menu.id, menu);
          store.menuStore.menus.push(menu);
          if (menu.childMenus)
            menu.childMenus = store.menuStore.sortMenu(menu.childMenus);
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  loadUsers = async () => {
    this.loadingUser = true;

    try {
      const result = await agent.Account.list(this.axiosParams);
      runInAction(() => {
        result.forEach((user) => {
          this.userRegistry.set(user.id, user);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingUser = false));
    }
  };

  get userList() {
    return Array.from(this.userRegistry);
  }

  loadUser = async (username: string) => {
    this.loadingForm = true;

    try {
      const user = await agent.Account.user(username);
      return user;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingForm = false;
      });
    }
  };

  getRoles = async () => {
    try {
      const roles = await agent.Account.roles();
      runInAction(() => (this.roles = roles));
    } catch (error) {
      console.log(error);
    }
  };

  roleCreate = async (role: RoleForm) => {
    try {
      await agent.Account.rolecreate(role).then((value) => {
        runInAction(() => {
          this.roles.push(value as Role);
        });
      });
      return "Role created success!";
    } catch (error) {
      throw error;
    }
  };

  register = async (creds: UserFormValues) => {
    try {
      await agent.Account.register(creds).then((values) => {
        runInAction(() => {
          this.userRegistry.set(values.id, values);
        });
      });
      return "User created success!";
    } catch (error) {
      throw error;
    }
  };

  update = async (username: string, creds: UserFormValues) => {
    try {
      await agent.Account.update(username, creds).then((values) => {
        runInAction(() => {
          this.userRegistry.set(values.id, values);
        });
      });
      return "User updated success!";
    } catch (error) {
      throw error;
    }
  };

  changeProfile = async (creds: ProfileFormValues) => {
    try {
      await agent.Account.changeProfile(creds).then((values) => {
        runInAction(() => {
          this.user = values;
          this.userRegistry.set(values.userName, values);
        });
      });
      return this.user;
    } catch (error) {
      throw error;
    }
  };
}
