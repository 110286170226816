import React from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores/store";
import ParentMenu from "./ParentMenu";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Skeleton from "@material-ui/lab/Skeleton";
import MenuList from "@material-ui/core/MenuList";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

interface Props {
  handleDrawerToggle: () => void;
  mobileOpen: boolean;
}

type StyleProps = {
  prefersDarkMode: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  drawer: {
    [theme.breakpoints.up("lg")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: ({ prefersDarkMode }) =>
      prefersDarkMode ? "#212121" : "#fff",
  },
}));

const drawerWidth = 275;

export default observer(function Sidebar({
  handleDrawerToggle,
  mobileOpen,
}: Props) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const classes = useStyles({ prefersDarkMode });
  const theme = useTheme();
  const { menuStore } = useStore();
  const { loadingMenu, menuList } = menuStore;
  const skeletonArray = Array(5).fill("");

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider style={{ margin: 0 }} />
      {loadingMenu ? (
        <>
          <MenuList>
            {skeletonArray.map((item, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <Skeleton variant="rect" width={20} />
                </ListItemIcon>
                <ListItemText>
                  <Skeleton variant="text" />
                </ListItemText>
              </ListItem>
            ))}
          </MenuList>
        </>
      ) : (
        <MenuList>
          {menuList.map(([id, menus]) => (
            <ParentMenu
              key={id}
              menu={menus}
              nested={false}
              bold={false}
              activeOnlyWhenExact={menus.isHome}
            />
          ))}
        </MenuList>
      )}
    </div>
  );

  return (
    <nav className={classes.drawer}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden lgUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
});
