import {
  CircularProgress,
  createTheme,
  Divider,
  Paper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useEffect } from "react";
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  TooltipProps,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";

export default observer(function SalesRevenue() {
  const classes = useStyles();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  );
  const { dashboardStore } = useStore();
  const { loadRevenueChart, revenueChart, loadingRevenueChart } =
    dashboardStore;

  useEffect(() => {
    loadRevenueChart();
  }, [loadRevenueChart]);

  const YFormater = (number: number) => {
    return number.toLocaleString();
  };

  const XFormater = (date: Date) => {
    return date.getDate().toString();
  };

  return (
    <Paper className={classes.dashboard_sales_revenue}>
      <Typography variant="subtitle2" component="h6">
        Sales Revenue
      </Typography>
      <Divider />
      {loadingRevenueChart ? (
        <div className={classes.center_div}>
          <CircularProgress />
        </div>
      ) : (
        <ResponsiveContainer width="100%" height="85%">
          <AreaChart
            data={revenueChart.map((x) => {
              return { date: new Date(x.date), revenue: x.revenue };
            })}
            margin={{
              top: 10,
              right: 0,
              left: 10,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#00bcd4" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#00bcd4" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="date"
              type="category"
              stroke={theme.palette.text.primary}
              tickFormatter={XFormater}
            />
            <YAxis
              type="number"
              stroke={theme.palette.text.primary}
              tickFormatter={YFormater}
            />
            <Tooltip content={<CustomTooltip />} />
            <Area
              type="monotone"
              dataKey="revenue"
              stroke="#00bcd4"
              fillOpacity={1}
              fill="url(#colorUv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </Paper>
  );
});

function CustomTooltip({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) {
  const classes = useStyles();

  if (active) {
    return (
      <Paper className={classes.form}>
        <Typography variant="subtitle2" component="h6">
          {`Sales Revenue ${moment(label).format("DD-MMM-yyyy")}`}
        </Typography>
        {payload?.map((entry, index) => (
          <Typography
            variant="subtitle2"
            key={index}
            component="p"
            color="primary"
          >
            {`IDR ${entry.value?.toLocaleString()}`}
          </Typography>
        ))}
      </Paper>
    );
  }

  return null;
}
