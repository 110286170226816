import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import { observer } from "mobx-react-lite";
import {
  StyledTableRow,
  StyledTableCell,
} from "../../app/common/table/EnhancedTableHead";
import { CashFlow, CashFlowType } from "../../app/models/cashFlow";

interface RowProps {
  row: CashFlow;
  dynamicRow: CashFlowType[];
  labelId: string;
  isItemSelected: boolean;
  handleClick: (event: React.MouseEvent<unknown>, id: string) => void;
}

export default observer(function CashFlowTableRow({
  row,
  dynamicRow,
  labelId,
  isItemSelected,
  handleClick,
}: RowProps) {
  return (
    <React.Fragment>
      <StyledTableRow
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          {row.canChange && (
            <Checkbox
              checked={isItemSelected}
              onClick={(event) => handleClick(event, row.id)}
            />
          )}
        </TableCell>
        <TableCell padding="checkbox">
          {row.canChange && (
            <Tooltip title="Update">
              <IconButton
                component={Link}
                to={`/cashflow/${row.id}`}
                color="primary"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
        <StyledTableCell component="th" id={labelId} scope="row">
          {row.date ? moment(row.date).format("DD-MMM-yyyy") : ""}
        </StyledTableCell>
        <StyledTableCell>{row.store ? row.store.name : ""}</StyledTableCell>
        <StyledTableCell>{row.documentNo}</StyledTableCell>
        <StyledTableCell>{row.description}</StyledTableCell>
        {dynamicRow.map((type) => {
          const display = row.cashFlowType?.id === type.id;
          return (
            <StyledTableCell key={type.id} align="right">
              {display ? row.amount.toLocaleString() : 0}
            </StyledTableCell>
          );
        })}
        <StyledTableCell align="right">
          {row.balance.toLocaleString()}
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
});
