import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
} from "@material-ui/core";
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";
import {Store} from "../../app/models/location";
import EditIcon from "@material-ui/icons/Edit";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { useStyles } from "../../app/layout/style";

interface Props {
  store: Store;
}

export default observer(function StoreCard({store}: Props) {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar className={classes.avatar}>
            {store.name.substring(5, 4)}
          </Avatar>
        }
        action={
          <IconButton
            component={Link}
            to={`/store/${store.id}`}
          >
            <EditIcon />
          </IconButton>
        }
        title={store.name}
        subheader={<SubHeaderCard store={store} />}
      />
      <CardContent className={classes.content}>
        <div className={classes.chip}>
          {!store.isActive && (
            <Chip
              icon={<SentimentVeryDissatisfiedIcon />}
              variant="outlined"
              color="secondary"
              label="Deleted"
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
});

function SubHeaderCard({store}: Props) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        marginTop: "8px",
      }}
    >
      <LocationCityIcon />
      <span style={{marginLeft: "8px"}}>{store.city}</span>
    </div>
  );
}
