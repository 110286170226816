import { Grid, Paper } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStyles } from "../../app/layout/style";
import PaymentTable from "./PaymentTable";
import PaymentChart from "./PaymentChart";

export default observer(function Payment() {
  const classes = useStyles();

  return (
    <Paper className={classes.dashboard_payment}>
      <Grid
        container
        direction="row"
        spacing={1}
      >
        <Grid item lg={6} md={12} xs={12}>
          <div className={classes.payment_chart}>
            <PaymentChart />
          </div>
        </Grid>
        <Grid item lg={6} md={12} xs={12}>
          <PaymentTable />
        </Grid>
      </Grid>
    </Paper>
  );
});
