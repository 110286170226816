import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { StockLocation, StockLocationFormValues } from "../models/location";

export default class StockLocationStore {
  stockLocationRegistry = new Map<string, StockLocation>();
  stockLocationDropdown: StockLocation[] = [];
  loadingStockLocation = false;
  predicate = new Map<string, string>();

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadStockLocations();
      }
    );
  }

  setPredicate = (value: string) => {
    this.predicate.delete("search");
    this.predicate.set("search", value);
  };

  get axiosParams() {
    const params = new URLSearchParams();
    if (this.predicate.size <= 1) {
      this.predicate.forEach((value, key) => {
        params.append(key, value);
      });
    }

    return params;
  }

  loadStockLocations = async () => {
    this.loadingStockLocation = true;

    try {
      const result = await agent.Location.list(this.axiosParams);
      runInAction(() => {
        this.stockLocationRegistry.clear();
        result.forEach((location) => {
          this.stockLocationRegistry.set(location.id, location);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingStockLocation = false));
    }
  };

  loadStockLocation = async (id: string) => {
    this.loadingStockLocation = true;

    try {
      const result = await agent.Location.details(id);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingStockLocation = false));
    }
  };

  loadDropDown = async () => {
    try {
      const dropdown = await agent.Location.dropdown();
      runInAction(() => {
        this.stockLocationDropdown = dropdown;
      });
    } catch (error) {
      console.log(error);
    }
  };

  get stockLocationList() {
    return Array.from(this.stockLocationRegistry);
  }

  createStockLocation = async (location: StockLocationFormValues) => {
    try {
      await agent.Location.create(location).then(() => {
        runInAction(() => {
          let newLocation = new StockLocation(location);
          this.stockLocationRegistry.set(newLocation.id, newLocation);
        });
      });
      return "Create stock location success!";
    } catch (error) {
      throw error;
    }
  };

  updateStockLocation = async (location: StockLocationFormValues) => {
    try {
      await agent.Location.update(location).then(() => {
        runInAction(() => {
          if (location.id) {
            this.stockLocationRegistry.set(
              location.id,
              location as StockLocation
            );
          }
        });
      });
      return "Stock location updated!";
    } catch (error) {
      throw error;
    }
  };

  loadStockLocationGranted = async () => {
    try {
      const dropdown = await agent.Location.granted();
      runInAction(() => {
        this.stockLocationDropdown = dropdown;
      });
    } catch (error) {
      console.log(error);
    }
  };
}
