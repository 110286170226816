export interface StockBalance {
  image: string;
  color: string;
  itemCode: string;
  itemDescription: string;
  unit: string;
  itemCategory: string;
  sizes: StockBalanceSize[];
}

interface StockBalanceSize {
  id: string;
  size: string;
  qty: number;
}

export class StockBalanceSearch {
  itemCode: string = "";
  date: Date = new Date();
  itemCategory: string = "";
  color: string = "";

  constructor(search?: StockBalanceSearch) {
    if (search) {
      this.itemCode = search.itemCode;
      this.date = search.date;
      this.itemCategory = search.itemCategory;
      this.color = search.color;
    }
  }
}
