import {
  IconButton,
  InputAdornment,
  TextField,
  BaseTextFieldProps,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import {useField} from "formik";
import {useStyles} from "../../layout/style";

interface Props extends BaseTextFieldProps {
  placeholder: string;
  name: string;
}

export default function SearchInput(props: Props) {
  const classes = useStyles();
  const [field, meta, helpers] = useField(props.name);

  return (
    <div>
      <TextField
        {...field}
        {...props}
        variant="outlined"
        size="small"
        className={classes.searchInput}
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error ? meta.error : props.helperText}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton type="submit" size="small">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={(e) => helpers.setValue("")}
                onMouseDown={(e) => e.preventDefault()}
              >
                {field.value && <ClearIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
