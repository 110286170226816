import {
  Grid,
  IconButton,
  Paper,
  TablePagination,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import ItemDeleteDialog from "../Items/ItemDeleteDialog";
import InvoiceCardSkeleton from "../Sales/InvoiceCardSkeleton";
import StockControlCard from "./StockControlCard";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import PostDialog from "../../app/layout/PostDialog";

export default observer(function StockControlCardList() {
  const classes = useStyles();
  const theme = useTheme();
  const widthSizeMatch = useMediaQuery(theme.breakpoints.up("xl"));
  const { stockControlStore, snackbarStore, dialogStore } = useStore();
  const {
    pageNumber,
    pageSize,
    documentList,
    loadingStockControl,
    deleteStockControl,
    postStockControl,
    setPageNumber,
    setPageSize,
    pagination,
    rowsOptions,
    setRowsOptions,
  } = stockControlStore;
  const { openSnackbar } = snackbarStore;
  const { openDialogDelete, closeDialog } = dialogStore;
  const skeletonCardArray = Array(widthSizeMatch ? 8 : 6).fill("");

  useEffect(() => {
    if (documentList.length === 0) {
      if (widthSizeMatch) {
        setRowsOptions([8, 8 * 2, 8 * 4, 8 * 8]);
        setPageSize(8);
      } else {
        setRowsOptions([6, 6 * 2, 6 * 4, 6 * 8]);
        setPageSize(6);
      }
    }
  }, [documentList.length, widthSizeMatch, setRowsOptions, setPageSize]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  const handleDeleteStock = (id: string) => {
    openDialogDelete(<ItemDeleteDialog id={id} />, handleDelete);
  };

  const handleDelete = (id: string) => {
    deleteStockControl([id])
      .catch((error) => {
        closeDialog();
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) openSnackbar(message, "success");
        closeDialog();
      });
  };

  const handleClickPost = (
    event: React.MouseEvent<unknown>,
    id: string,
    posted: boolean
  ) => {
    openDialogDelete(
      <PostDialog
        title={`Are you sure to ${posted ? "unpost" : "post"} this document`}
        id={id}
      />,
      handlePost
    );
  };

  const handlePost = (id: string) => {
    postStockControl(id)
      .catch((error) => {
        closeDialog();
        openSnackbar(error, "error");
      })
      .then((message) => {
        if (message !== undefined) openSnackbar(message, "success");
        closeDialog();
      });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper className={classes.paperCard}>
          <Toolbar>
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {loadingStockControl ? (
                <Skeleton variant="text" width="30%" />
              ) : (
                "Stock Control List"
              )}
            </Typography>
            {loadingStockControl ? (
              <Skeleton variant="circle" width={30} height={30} />
            ) : (
              <Tooltip title="Create">
                <IconButton
                  component={Link}
                  to={"/stockcontrol/form"}
                  color="primary"
                  size="medium"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
          </Toolbar>
        </Paper>
      </Grid>
      {loadingStockControl
        ? skeletonCardArray.map((item, index) => (
            <Grid key={index} item md={4} sm={6} xs={12}>
              <InvoiceCardSkeleton />
            </Grid>
          ))
        : documentList.map((stock) => (
            <Grid key={stock.id} item md={4} sm={6} xs={12}>
              <StockControlCard
                stock={stock}
                deleteStock={handleDeleteStock}
                handlePost={handleClickPost}
              />
            </Grid>
          ))}
      <Grid item xs={12}>
        <Paper className={classes.transferList}>
          <TablePagination
            rowsPerPageOptions={rowsOptions}
            component="div"
            count={pagination === null ? 0 : pagination.totalItems}
            page={pageNumber}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>
  );
});
