import { Divider, Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { SalesDetail } from "../../app/models/sale";
import { useStore } from "../../app/stores/store";

interface Props {
  details: SalesDetail[];
  disc?: number;
}

export default observer(function POSTotal({ details, disc }: Props) {
  const { salesStore } = useStore();
  const { getSubTotal, getDiscSubTotal, getTotalQty } = salesStore;
  const subTotal = getSubTotal(details);
  const discSubTotal = getDiscSubTotal(details);
  const totalQty = getTotalQty(details);
  const discTotal = disc !== undefined ? (subTotal * disc) / 100 : 0;
  const totalAmount = subTotal - discTotal;

  return (
    <>
      <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
        <Grid item xs>
          <Typography variant="body1" component="p">
            Sub Total ({totalQty}):
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="right" variant="body1" component="p">
            {subTotal.toLocaleString()}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
        <Grid item xs>
          <Typography variant="body2" component="p">
            Discount:
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="right" variant="body2" component="p">
            {(discSubTotal + discTotal).toLocaleString("EN-US")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
        <Grid item xs>
          <Typography variant="body2" component="p">
            Tax:
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="right" variant="body2" component="p">
            0
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
        <Grid item xs>
          <Typography variant="h5" component="p">
            Total Amount :
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="right" variant="h5" component="p">
            {totalAmount.toLocaleString("EN-US")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
});
