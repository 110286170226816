import { TableContainer, Table, TableBody, Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { observer } from "mobx-react-lite";
import React from "react";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../app/common/table/EnhancedTableHead";
import { useStyles } from "../../app/layout/style";
import { SalesPaymentChart } from "../../app/models/dashboard";
import { HeadCell, Order } from "../../app/models/table";
import { useStore } from "../../app/stores/store";
import PaymentTableHead from "./PaymentTableHead";
import PaymentTableRow from "./PaymentTableRow";

export default observer(function PaymentTable() {
  const classes = useStyles();
  const { dashboardStore, tableStore } = useStore();
  const { paymentChart, loadingPaymentChart } = dashboardStore;
  const { stableSort, getComparator } = tableStore;
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    React.useState<keyof SalesPaymentChart>("paymentType");
  const headCells: HeadCell<SalesPaymentChart>[] = [
    {
      id: "paymentType",
      align: "left",
      disablePadding: false,
      label: "Type",
      disableSort: false,
      skeletonShape: "text",
    },
    {
      id: "amount",
      align: "right",
      disablePadding: false,
      label: "Amount",
      disableSort: true,
      skeletonShape: "text",
    },
  ];
  const skeletonRowArray = Array(4).fill("");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property as keyof SalesPaymentChart);
  };

  return (
    <Paper>
      <TableContainer className={classes.payment_table}>
        <Table size="small" component="table">
          <PaymentTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            loading={loadingPaymentChart}
          />
          <TableBody>
            {loadingPaymentChart ? (
              <>
                {skeletonRowArray.map((item, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <Skeleton variant="text" />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Skeleton variant="text" />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </>
            ) : paymentChart.length === 0 ? (
              <StyledTableRow>
                <StyledTableCell colSpan={headCells.length + 1}>
                  No Data.
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              stableSort(paymentChart, getComparator(order, orderBy)).map(
                (row, index) => <PaymentTableRow row={row} key={index} />
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
});
