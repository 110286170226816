import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { StockBalance, StockBalanceSearch } from "../models/inventories";
import { Size } from "../models/item";
import { HeadCell, Order, SortBy } from "../models/table";

export default class InventoriesStore {
  headCells: HeadCell<any>[] = [];
  sizes: Size[] = [];
  stockBalance: StockBalance[] = [];
  loadingStockBalance = false;
  predicate = new Map<string, string>();
  sortBy: SortBy<StockBalance>[] = [
    { id: "color", label: "Color" },
    { id: "itemCode", label: "Item Code" },
    { id: "itemCategory", label: "Item Category" },
  ];
  orderBy: Order = "asc";

  constructor() {
    makeAutoObservable(this);
    this.predicate.set("date", new Date().toLocaleDateString("EN-US"));
    this.predicate.set("search", "");
    this.predicate.set("orderBy", this.orderBy);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadStockBalance();
      }
    );
  }

  setPredicate = (search: StockBalanceSearch) => {
    Object.keys(search).forEach((key) => {
      this.predicate.delete(key);
    });
    this.predicate.set("date", new Date().toLocaleDateString("EN-US"));
    this.predicate.set("itemCode", search.itemCode);
    this.predicate.set("itemCategory", search.itemCategory);
    this.predicate.set("color", search.color);
  };

  setSortBy = (value: Extract<keyof StockBalance, string> | null) => {
    this.predicate.delete("sortBy");
    if (value !== null) this.predicate.set("sortBy", value.toString());
  };

  setOrderBy = (value: Order) => {
    this.predicate.delete("orderBy");
    this.predicate.set("orderBy", value.toString());
    this.orderBy = value;
  };

  get axiosParams() {
    const params = new URLSearchParams();
    this.predicate.forEach((value, key) => {
      params.delete(key);
      params.append(key, value);
    });

    return params;
  }

  loadSize = async () => {
    this.loadingStockBalance = true;

    try {
      const sizes = await agent.Items.loadSizes();
      runInAction(() => {
        this.sizes = sizes;
        this.setStockBalanceHeader();
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingStockBalance = false));
    }
  };

  loadStockBalance = async () => {
    this.loadingStockBalance = true;

    try {
      const result = await agent.Inventories.stockBalance(this.axiosParams);
      runInAction(() => {
        this.stockBalance = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingStockBalance = false));
    }
  };

  private setStockBalanceHeader() {
    this.headCells = [
      {
        id: "image",
        align: "center",
        disablePadding: false,
        label: "",
        disableSort: true,
        skeletonShape: "circle",
      },
      {
        id: "itemCategory",
        align: "left",
        disablePadding: false,
        label: "Category",
        disableSort: false,
        skeletonShape: "text",
      },
      {
        id: "itemCode",
        align: "left",
        disablePadding: false,
        label: "Item Code",
        disableSort: false,
        skeletonShape: "text",
      },
      {
        id: "itemDescription",
        align: "left",
        disablePadding: false,
        label: "Description",
        disableSort: false,
        skeletonShape: "text",
      },
      {
        id: "color",
        align: "left",
        disablePadding: false,
        label: "Color",
        disableSort: false,
        skeletonShape: "text",
      },
    ];
    this.sizes.forEach((size) => {
      this.headCells.push({
        id: size.description,
        align: "center",
        disablePadding: false,
        label: size.description,
        disableSort: true,
        skeletonShape: "text",
      });
    });
    this.headCells.push({
      id: "total",
      align: "center",
      disablePadding: false,
      label: "Total",
      disableSort: true,
      skeletonShape: "text",
    });
  }
}
