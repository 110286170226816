import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { Pagination } from "../models/pagination";
import {
  StockControl,
  StockControlDetail,
  StockControlFormValues,
  StockTransactionType,
} from "../models/stockControl";
import { Order, SortBy } from "../models/table";

export default class StockControlStore {
  documentRegistry = new Map<string, StockControl>();
  document: StockControl | undefined;
  stockTransactionType: StockTransactionType[] = [];
  loadingStockControl = false;
  predicate = new Map<string, string>();
  rowsOptions = [6];
  pagination: Pagination | null = null;
  sortBy: Extract<keyof StockControl, string> = "date";
  sortByCard: SortBy<StockControl>[] = [
    { id: "date", label: "Date" },
    { id: "documentNo", label: "Document No." },
    { id: "stockLocationName", label: "Stock Location" },
  ];
  orderBy: Order = "desc";

  constructor() {
    makeAutoObservable(this);
    this.predicate.set("pageNumber", "1");
    this.predicate.set("sortBy", "");
    this.predicate.set("orderBy", this.orderBy);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.loadDocuments();
      }
    );
  }

  setPredicate = (value: string) => {
    this.predicate.delete("search");
    this.predicate.set("search", value);
  };

  setPageNumber = (value: number) => {
    this.predicate.delete("pageNumber");
    this.predicate.set("pageNumber", value.toString());
  };

  setPageSize = (value: number) => {
    this.predicate.delete("pageSize");
    this.predicate.set("pageSize", value.toString());
    // this.predicate.set("pageNumber", "1");
  };

  setSortBy = (value: Extract<keyof StockControl, string>, order: Order) => {
    this.predicate.delete("sortBy");
    this.predicate.set("sortBy", value.toString());
    this.sortBy = value;
    this.predicate.delete("orderBy");
    this.predicate.set("orderBy", order);
    this.orderBy = order;
  };

  setSortByCard = (value: Extract<keyof StockControl, string> | null) => {
    this.predicate.delete("sortBy");
    if (value !== null) this.predicate.set("sortBy", value.toString());
  };

  setOrderBy = (value: Order) => {
    this.predicate.delete("orderBy");
    this.predicate.set("orderBy", value.toString());
    this.orderBy = value;
  };

  setRowsOptions = (value: number[]) => {
    this.rowsOptions = value;
  };

  get axiosParams() {
    const params = new URLSearchParams();
    this.predicate.forEach((value, key) => {
      params.delete(key);
      params.append(key, value);
    });
    return params;
  }

  get pageNumber() {
    return Number(this.predicate.get("pageNumber")) - 1;
  }

  get pageSize() {
    return Number(this.predicate.get("pageSize"));
  }

  loadDocuments = async () => {
    this.loadingStockControl = true;

    try {
      const result = await agent.StockControls.list(this.axiosParams);
      runInAction(() => {
        this.documentRegistry.clear();
        result.data.forEach((item) => {
          let itemResult = {
            ...item,
            details: this.sortDetails(item.details),
          };
          this.documentRegistry.set(item.id, itemResult);
        });
        this.pagination = result.pagination;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingStockControl = false));
    }
  };

  get documentList() {
    return Array.from(this.documentRegistry.values());
  }

  loadTransactionType = async () => {
    this.loadingStockControl = true;

    try {
      const result = await agent.StockControls.loadTransactionType();
      runInAction(() => {
        this.stockTransactionType = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingStockControl = false));
    }
  };

  loadDocument = async (id: string) => {
    this.loadingStockControl = true;

    try {
      const result = await agent.StockControls.details(id);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingStockControl = false));
    }
  };

  createStockControl = async (document: StockControlFormValues) => {
    try {
      if (document.details) {
        for await (const x of document.details) {
          x.stockControlId = document.id;
        }
      }

      await agent.StockControls.create(document);
      this.loadDocuments();
      return "Create stock control document is success!";
    } catch (error) {
      throw error;
    }
  };

  updateStockControl = async (document: StockControlFormValues) => {
    try {
      await agent.StockControls.update(document).then((document) => {
        runInAction(() => {
          let documentResult = {
            ...document,
            details: this.sortDetails(document.details),
          };
          this.documentRegistry.set(document.id, documentResult);
        });
      });
      return "Update stock control document is success!";
    } catch (error) {
      throw error;
    }
  };

  deleteStockControl = async (ids: string[]) => {
    this.loadingStockControl = true;

    try {
      await agent.StockControls.delete(ids);
      this.loadDocuments();
      return "Delete stock control(s) success!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingStockControl = false));
    }
  };

  postStockControl = async (id: string) => {
    this.loadingStockControl = true;

    try {
      await agent.StockControls.post(id).then(() => {
        runInAction(() => {
          const document = this.documentRegistry.get(id);
          if (document) {
            document.posted = !document.posted;
            this.documentRegistry.set(id, document);
          }
        });
      });
      return "Document is posted!";
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingStockControl = false));
    }
  };

  sortDetails = (details: StockControlDetail[]) => {
    return details.sort((a, b) => {
      if (a.itemCode > b.itemCode) return 1;
      if (b.itemCode > a.itemCode) return -1;
      return 0;
    });
  };
}
