import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import { StockTransactionType } from "../../app/models/stockControl";
import { useStyles } from "../../app/layout/style";

interface Props {
  options: StockTransactionType[];
  value: StockTransactionType | null;
  setValue: (value: StockTransactionType | null) => void;
  optionDisabled?: StockTransactionType[];
  loading: boolean;
}

export default function StockControlAutoComplete({
  options,
  value,
  setValue,
  optionDisabled,
  loading,
}: Props) {
  const classes = useStyles();
  const handleOptionDisabled = (group: StockTransactionType) => {
    if (optionDisabled) return optionDisabled.some((x) => x.id === group.id);
    else return false;
  };

  return (
    <FormControl fullWidth className={classes.icon}>
      <Autocomplete
        id="search-groups"
        options={options}
        getOptionLabel={(option) => option.type}
        getOptionDisabled={(option) => handleOptionDisabled(option)}
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue !== "string") {
            setValue(newValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Type"
            placeholder="Please select any transaction type."
            margin="dense"
          />
        )}
        disabled={loading}
      />
    </FormControl>
  );
}
