import {
  Grid,
  Avatar,
  Typography,
  Tooltip,
  IconButton,
  Divider,
  Chip,
  CircularProgress,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { QtyInputField } from "../../app/common/form/NumberInputField";
import { SalesDetail, SalesFormValues } from "../../app/models/sale";
import DeleteIcon from "@material-ui/icons/Delete";
import { ChangeEvent } from "react";
import { useStyles } from "../../app/layout/style";

interface Props {
  sales: SalesFormValues;
  setSales: (sales: SalesFormValues) => void;
  details: SalesDetail[];
  setDetails: (details: SalesDetail[]) => void;
  isSubmitting: boolean;
  loading: boolean;
}

export default observer(function POSCart({
  sales,
  setSales,
  details,
  setDetails,
  isSubmitting,
  loading,
}: Props) {
  const classes = useStyles();

  const handleQtyChange = (
    e: ChangeEvent<{
      value: unknown;
    }>,
    index: number
  ) => {
    const qty = Number(e.target.value);
    let items = [...details];
    let item = { ...details[index] };

    item.totalAmount = qty * item.price - (qty * item.price * item.disc) / 100;
    item.qty = qty;
    items[index] = item;
    setDetails(items);
  };

  const handleDiscChange = (
    e: ChangeEvent<{
      value: unknown;
    }>,
    index: number
  ) => {
    const disc = Number(e.target.value);
    let items = [...details];
    let item = { ...details[index] };

    item.totalAmount =
      item.qty * item.price - (item.qty * item.price * disc) / 100;
    item.disc = disc;
    items[index] = item;
    setDetails(items);
  };

  const handleDeleteItem = (detail: SalesDetail) => {
    setDetails(details.filter((x) => x.id !== detail.id));
    setSales(
      new SalesFormValues({
        ...sales,
        details: sales.details.filter((x) => x.id !== detail.id),
      })
    );
  };

  return (
    <>
      {loading ? (
        <div className={classes.center_div}>
          <CircularProgress />
        </div>
      ) : (
        details.map((detail, index) => (
          <div key={index}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              wrap="nowrap"
            >
              <Grid item>
                <Avatar
                  alt={detail.itemCode}
                  src={detail.image ? detail.image.url : "/no-image.png"}
                />
              </Grid>
              <Grid item>
                <Chip
                  variant="outlined"
                  color="primary"
                  label={detail.size.description}
                />
              </Grid>
              <Grid item xs>
                <Typography variant="body2" component="p">
                  {detail.itemCode}
                </Typography>
                <Typography variant="caption" component="p">
                  {detail.color.description}
                </Typography>
              </Grid>
              <Grid item>
                <QtyInputField
                  variant="outlined"
                  label="Qty"
                  name={`details[${index}].qty`}
                  margin="dense"
                  andorment={detail.unit.description}
                  disabled={isSubmitting}
                  onChange={(e) => handleQtyChange(e, index)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Delete Item">
                  <IconButton
                    component="div"
                    size="small"
                    onClick={() => handleDeleteItem(detail)}
                    disabled={isSubmitting}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <QtyInputField
                  variant="outlined"
                  label="Discount"
                  name={`details[${index}].disc`}
                  margin="dense"
                  andorment="%"
                  disabled={isSubmitting}
                  onChange={(e) => handleDiscChange(e, index)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="flex-end">
                  <Grid item>
                    <Typography
                      className={classes.price}
                      variant="body2"
                      component="span"
                      align="right"
                    >
                      IDR {detail.price.toLocaleString("en-US")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="flex-end">
                  <Grid item>
                    <Typography align="right" variant="subtitle1" component="p">
                      Total: {detail.totalAmount.toLocaleString("en-US")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
          </div>
        ))
      )}
    </>
  );
});
