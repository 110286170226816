import Paper from "@material-ui/core/Paper";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Dropdown from "../../app/common/form/Dropdown";
import { useStyles } from "../../app/layout/style";
import { useStore } from "../../app/stores/store";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateInputField from "../../app/common/form/DateInputFIeld";
import {
  StockControl,
  StockControlDetail,
  StockControlDetailValues,
  StockControlFormValues,
} from "../../app/models/stockControl";
import StockControlFormAddDetails from "./StockControlFormAddDetails";
import { ItemDropdown } from "../../app/models/item";

export default observer(function StockOutgoingForm() {
  const classes = useStyles();
  const { stockControlStore, snackbarStore, stockLocationStore, itemStore } =
    useStore();
  const { loadDropDownItem } = itemStore;
  const {
    loadingStockControl,
    loadTransactionType,
    loadDocument,
    createStockControl,
    updateStockControl,
  } = stockControlStore;
  const { loadStockLocationGranted, stockLocationDropdown } =
    stockLocationStore;
  const { openSnackbar } = snackbarStore;
  const [stockControl, setStockControl] = useState<StockControlFormValues>(
    new StockControlFormValues()
  );
  const [details, setDetails] = useState<StockControlDetail[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<ItemDropdown[]>([]);
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadStockLocationGranted();
    loadDropDownItem();
    loadTransactionType();
    if (id) {
      loadDocument(id).then((result) => {
        loadDocumentResult(result);
      });
    }
  }, [id, loadStockLocationGranted, loadTransactionType, loadDropDownItem, loadDocument]);

  const loadDocumentResult = (result: StockControl | undefined) => {
    if (result) {
      setDetails(result.details);
      setStockControl(
        new StockControlFormValues({
          id: result.id,
          date: result.date,
          stockLocationId: result.stockLocationId,
          details: result.details.map((detail) => {
            return {
              id: detail.id,
              stockControlId: result.id,
              itemId: detail.itemId,
              sizeId: detail.size.id,
              stockTransactionTypeId: detail.stockTransactionType.id,
              qty: detail.qty,
              notes: detail.notes,
            } as StockControlDetailValues;
          }),
        })
      );
      setSelectedGroup(
        result.details.map((detail) => {
          return {
            id: detail.itemId,
            itemCategory: detail.itemCategory,
            color: detail.color,
            itemDescription: detail.itemDescription,
            itemCode: detail.itemCode,
            unit: detail.unit,
            image: detail.image,
            size: detail.size,
          } as ItemDropdown;
        })
      );
    }
  };

  const schema = Yup.object({
    date: Yup.date().required("Date is required."),
    stockLocationId: Yup.string().required("Stock location field is required."),
    details: Yup.array().of(
      Yup.object().shape({
        qty: Yup.number()
          .typeError("Quantity is must be a number.")
          .required("Quantity is required."),
      })
    ),
  });

  const handleFormSubmit = (
    stockControl: StockControlFormValues,
    resetForm: () => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (!stockControl.id) {
      let newStockControl = {
        ...stockControl,
        id: uuid(),
      };
      createStockControl(newStockControl)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            setStockControl(new StockControlFormValues());
            setDetails([]);
            resetForm();
          }
        });
    } else {
      updateStockControl(stockControl)
        .catch((error) => {
          openSnackbar(error, "error");
          setSubmitting(false);
        })
        .then((message) => {
          if (message !== undefined) {
            openSnackbar(message, "success");
            history.push("/stockcontrol");
          }
        });
    }
  };

  return (
    <>
      <Paper className={classes.form}>
        <Formik
          validationSchema={schema}
          enableReinitialize
          initialValues={stockControl}
          onSubmit={(values, { resetForm, setSubmitting }) =>
            handleFormSubmit(values, resetForm, setSubmitting)
          }
        >
          {({
            handleSubmit,
            isSubmitting,
            isValid,
            values,
          }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Typography variant="h5">
                Form {id ? "Update" : "Create"}
              </Typography>
              <Divider />
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <DateInputField
                    label="Date"
                    name="date"
                    placeholder="Please input Date"
                    disabled={isSubmitting || loadingStockControl}
                    maxDate={new Date()}
                    inputVariant="standard"
                    margin="normal"
                  />
                  <Dropdown
                    options={stockLocationDropdown.map((x) => {
                      return { text: x.name, value: x.id };
                    })}
                    name="stockLocationId"
                    placeholder="Stock Location"
                    label="Stock Location"
                    disabled={isSubmitting || loadingStockControl}
                  />
                </Grid>
                <Grid item xs={12} lg={8}>
                  <StockControlFormAddDetails
                    stockControl={values}
                    setStockControl={setStockControl}
                    details={details}
                    setDetails={setDetails}
                    selectedGroup={selectedGroup}
                    setSelectedGroup={setSelectedGroup}
                    isSubmitting={isSubmitting}
                    loading={loadingStockControl}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="stretch"
                spacing={1}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting || !isValid || values.details.length === 0}
                  >
                    {isSubmitting && (
                      <CircularProgress
                        className={classes.progress}
                        size={16}
                        color="inherit"
                      />
                    )}
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="default"
                    component={Link}
                    to="/stockcontrol"
                    disabled={isSubmitting || loadingStockControl}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
});
