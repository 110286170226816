import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { observer } from "mobx-react-lite";
import React from "react";
import { User } from "../../app/models/user";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import EmailIcon from "@material-ui/icons/Email";
import EditIcon from "@material-ui/icons/Edit";
import Chip from "@material-ui/core/Chip";
import { Link } from "react-router-dom";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { useStyles } from "../../app/layout/style";

interface Props {
  user: User;
}

export default observer(function UserCard({ user }: Props) {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar className={classes.avatar}>
            {user.displayName.substring(0, 1)}
          </Avatar>
        }
        action={
          <IconButton component={Link} to={`/registeruser/${user.userName}`}>
            <EditIcon />
          </IconButton>
        }
        title={user.displayName}
        subheader={<SubHeaderCard user={user} />}
      />
      <CardContent className={classes.content}>
        {user.roles && (
          <div className={classes.chip}>
            {user.roles.map((role, index) => (
              <Chip
                variant="outlined"
                color="primary"
                key={index}
                label={role.name}
              />
            ))}
            {!user.isActive && (
              <Chip
                icon={<SentimentVeryDissatisfiedIcon />}
                variant="outlined"
                color="secondary"
                label="Inactive"
              />
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
});

function SubHeaderCard({ user }: Props) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        marginTop: "8px",
      }}
    >
      <EmailIcon />
      <span style={{ marginLeft: "8px" }}>{user.email}</span>
    </div>
  );
}
