import React from "react";
import clsx from "clsx";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Divider } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

interface EnhancedTableToolbarProps {
  title: string | null;
  numSelected: number;
  handleDelete: (e: React.MouseEvent<unknown>) => void;
  linkAddNew?: string | undefined;
  loading: boolean;
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  })
);

export default observer(function EnhancedTableToolbar({
  title,
  numSelected,
  handleDelete,
  linkAddNew,
  loading = false,
}: EnhancedTableToolbarProps) {
  const classes = useToolbarStyles();

  return (
    <>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {loading ? <Skeleton variant="text" width="30%" /> : title}
          </Typography>
        )}
        {loading ? (
          <Skeleton variant="circle" width={30} height={30} />
        ) : numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton onClick={(e) => handleDelete(e)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          linkAddNew && (
            <Tooltip title="Create">
              <IconButton
                component={Link}
                to={linkAddNew ? linkAddNew : ""}
                color="primary"
                size="medium"
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          )
        )}
      </Toolbar>
      <Divider />
    </>
  );
});
