import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { Item } from "../../app/models/item";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useStyles } from "../../app/layout/style";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  item: Item;
  deleteItem: (id: string) => void;
}

export default observer(function ItemCard({ item, deleteItem }: Props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = (id: string) => {
    setAnchorEl(null);
    deleteItem(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar
            src={`/${item.itemCategory.icon}`}
            className={classes.large}
          ></Avatar>
        }
        action={
          <>
            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem component={Link} to={`/items/${item.id}`}>
                Update
              </MenuItem>
              <MenuItem onClick={() => handleDelete(item.id)}>Delete</MenuItem>
            </Menu>
          </>
        }
        title={item.itemDescription}
        subheader={item.fullCode}
      />
      <CardMedia
        className={classes.media}
        image={item.image ? item.image.url : "/no-image.png"}
        title={item.itemDescription}
      />
      <CardActions disableSpacing>
        <div className={classes.chip_start}>
          <Chip
            variant="outlined"
            color="primary"
            label={item.itemCategory.description}
          />
          <Chip
            variant="outlined"
            color="default"
            label={item.color.description}
          />
          <Chip
            variant="outlined"
            color="primary"
            label={item.unit.description}
          />
        </div>
        {!item.isActive && (
          <Chip
            icon={<SentimentVeryDissatisfiedIcon />}
            variant="outlined"
            color="secondary"
            label="Deleted"
          />
        )}
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {item.sizes.map((row) => (
            <Paper
              key={row.sizeId}
              variant="outlined"
              className={classes.itemCard}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Chip variant="outlined" color="primary" label={row.size} />
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {item.itemCode}
                    {item.color.code}
                    {row.size}
                  </Typography>
                  <Typography variant="caption">
                    {`Cost (IDR): ${row.cost?.toLocaleString()} Price (IDR): ${row.price?.toLocaleString()} Disc:${row.disc?.toLocaleString()}%`}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </CardContent>
      </Collapse>
    </Card>
  );
});
