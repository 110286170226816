import { Typography, Divider, Grid, Hidden } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { observer } from "mobx-react-lite";
import React from "react";
import { Helmet } from "react-helmet";
import { useStore } from "../../app/stores/store";
import ReportSalesTrendCardList from "./ReportSalesTrendCardList";
import ReportSalesTrendFilter from "./ReportSalesTrendFilter";
import ReportSalesTrendTable from "./ReportSalesTrendTable";

export default observer(function ReportSalesTrend() {
  const { menuStore } = useStore();
  const { loadingMenu } = menuStore;

  return (
    <>
      {loadingMenu ? (
        <>
          <Typography variant="h4">
            <Skeleton width="50%" />
          </Typography>
          <Divider />
        </>
      ) : (
        <>
          <Helmet>
            <title>KEDJORA BOUTIQUE - Report Sales Trend</title>
          </Helmet>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h4">Report Sales Trend</Typography>
            </Grid>
            <Grid item>
              <ReportSalesTrendFilter />
            </Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Grid item xs={12}>
              <Hidden mdDown implementation="css">
                <ReportSalesTrendTable />
              </Hidden>
            </Grid>
          </Grid>
          <Hidden lgUp implementation="css">
            <ReportSalesTrendCardList />
          </Hidden>
        </>
      )}
    </>
  );
});
