import {
  Card,
  CardContent,
  makeStyles,
  createStyles,
  Typography,
} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    media: {
      height: 0,
      paddingTop: "100%", // 16:9
    },
  })
);

export default function ItemCardSkeleton() {
  const classes = useStyles();

  return (
    <Card>
      <Skeleton animation="wave" variant="rect" className={classes.media} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          <Skeleton width="50%" />
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          <Skeleton width="80%" />
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          <Skeleton width="50%" />
        </Typography>
      </CardContent>
    </Card>
  );
}
