import { Grid } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { usePosStyles } from "../../app/layout/pos";
import { useStyles } from "../../app/layout/style";
import { Sale } from "../../app/models/sale";
import { useStore } from "../../app/stores/store";

type Props = {
  componentToPrint: React.MutableRefObject<HTMLDivElement | null>;
  sales?: Sale | undefined;
};

export default observer(function POSSlip(props: Props) {
  const classes = useStyles();
  const posStyles = usePosStyles();
  const { salesStore } = useStore();
  const { getTotalQty, getSubTotal } = salesStore;
  const { componentToPrint, sales } = props;
  const totalQty = getTotalQty(sales?.details);
  const subTotal = getSubTotal(sales?.details);
  const discTotal =
    sales?.disc !== undefined ? (subTotal * sales?.disc) / 100 : 0;
  const totalAmount = subTotal - discTotal;
  const totalPayment = sales?.payments.reduce(
    (total, currentData) => (total = total + currentData.amount),
    0
  );

  return (
    <div
      ref={(el) => (componentToPrint.current = el)}
      className={posStyles.slip}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <img src="/kedjora.png" alt="logo" className={classes.logo} />
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={posStyles.address}
      >
        <Grid item>
          <span>{sales?.store.city.toUpperCase()}</span>
        </Grid>
        <Grid item>
          <span>IG: @kedjora.boutique</span>
        </Grid>
        <Grid item>
          <span>Phone No: +6285737414232</span>
        </Grid>
      </Grid>
      <hr />
      <table>
        <tbody>
          <tr>
            <td>Date</td>
            <td>:</td>
            <td>{moment(sales?.date).format("DD-MMM-yyyy")}</td>
          </tr>
          <tr>
            <td>Invoice</td>
            <td>:</td>
            <td>{sales?.documentNo}</td>
          </tr>
          <tr>
            <td>Store</td>
            <td>:</td>
            <td>{sales?.store.name}</td>
          </tr>
          <tr>
            <td>Customer</td>
            <td>:</td>
            <td>{sales?.customerName}</td>
          </tr>
        </tbody>
      </table>
      <h4>Details</h4>
      <table className={posStyles.slip_table}>
        <thead className={posStyles.slip_table}>
          <tr>
            <th>Item Code</th>
            <th>Disc</th>
            <th>Price</th>
            <th>Qty</th>
            <th>Total Amount</th>
          </tr>
        </thead>
        <tbody>
          {sales?.details.map((detail, index) => (
            <tr key={index}>
              <td>{detail.itemCode}</td>
              <td>{detail.disc.toLocaleString()}</td>
              <td>{detail.price.toLocaleString()}</td>
              <td>{detail.qty.toLocaleString()}</td>
              <td>{detail.totalAmount.toLocaleString()}</td>
            </tr>
          ))}
          <tr style={{ fontWeight: "bold" }}>
            <td colSpan={3} align="right">
              Sub Total:
            </td>
            <td>{totalQty.toLocaleString()}</td>
            <td>{subTotal.toLocaleString()}</td>
          </tr>
          <tr style={{ fontWeight: "bold" }}>
            <td colSpan={3} align="right">
              Discount:
            </td>
            <td>{sales?.disc}%</td>
            <td>{discTotal.toLocaleString()}</td>
          </tr>
          <tr style={{ fontWeight: "bold" }}>
            <td colSpan={3} align="right">
              Total:
            </td>
            <td></td>
            <td>{totalAmount.toLocaleString()}</td>
          </tr>
        </tbody>
      </table>
      <h4>Payments</h4>
      <table className={posStyles.slip_table}>
        <tbody>
          {sales?.payments.map((payment, index) => (
            <tr key={index}>
              <td align="left">{payment.paymentType.type}</td>
              <td align="right">{payment.amount.toLocaleString()}</td>
            </tr>
          ))}
          <tr style={{ fontWeight: "bold" }}>
            <td align="right">Total:</td>
            <td align="right">{totalPayment?.toLocaleString()}</td>
          </tr>
        </tbody>
      </table>
      <div className={posStyles.page_break} />
    </div>
  );
});
