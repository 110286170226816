import { Documents } from "./document";
import { ItemDocument, ItemDropdown, Size } from "./item";
import { Store } from "./location";

export interface PaymentType {
  id: string;
  type: string;
  needAccountNo: boolean;
  isVerified: boolean;
  display: boolean;
}

export interface BankAccount {
  id: string;
  accountNo: string;
  bankName: string;
  owner: string;
}

export class SalesTrendSearch {
  itemCode: string = "";
  date: Date = new Date();
  itemCategory: string = "";
  color: string = "";

  constructor(search?: SalesTrendSearch) {
    if (search) {
      this.itemCode = search.itemCode;
      this.date = search.date;
      this.itemCategory = search.itemCategory;
      this.color = search.color;
    }
  }
}

export interface SalesTrend {
  image: string;
  itemCategory: string;
  itemDescription: string;
  itemCode: string;
  color: string;
  unit: string;
  size: string;
  qty: string;
  details: SalesTrendDetail[];
}

export interface SalesTrendDetail {
  documentNo: string;
  date: Date;
  storeName: string;
  customerName: string;
  price: number;
  disc: number;
}

export class InvoiceListSearch {
  id: string = "";
  startDate: Date = new Date();
  endDate: Date = new Date();
  customerName: string = "";
  documentNo: string = "";

  constructor(search?: InvoiceListSearch) {
    if (search) {
      this.id = search.id;
      this.startDate = search.startDate;
      this.endDate = search.endDate;
      this.customerName = search.customerName;
      this.documentNo = search.documentNo;
    }
  }
}

export interface SalesDetail extends ItemDocument {
  id: string;
  salesId?: string;
  itemId: string;
  size: Size;
  disc: number;
  qty: number;
  price: number;
  totalAmount: number;
}

export class SalesDetail implements SalesDetail {
  constructor(id: string, qty: number, salesId?: string, item?: ItemDropdown) {
    if (item) {
      this.id = id;
      this.salesId = salesId;
      this.itemId = item.id;
      this.color = item.color;
      this.itemCategory = item.itemCategory;
      this.itemCode = item.itemCode;
      this.itemDescription = item.itemDescription;
      this.image = item.image;
      this.unit = item.unit;
      this.price = item.price;
      this.qty = qty;
      this.disc = item.disc;
      this.size = item.size;
      this.totalAmount =
        this.qty * this.price - (this.qty * this.price * this.disc) / 100;
    }
  }
}

export interface SalesPayment {
  id: string;
  salesId: string;
  paymentType: PaymentType;
  bankAccount?: BankAccount;
  date: Date;
  amount: number;
  inputDate: Date;
}

export interface Sale extends Documents {
  customerName: string;
  store: Store;
  tax: number;
  disc: number;
  voucher: string;
  details: SalesDetail[];
  payments: SalesPayment[];
}

export class SalesDetailValues {
  id?: string = undefined;
  salesId?: string = "";
  itemId: string = "";
  sizeId: string = "";
  disc?: number = undefined;
  qty?: number = undefined;
  price?: number = undefined;

  constructor(detail?: SalesDetailValues) {
    if (detail) {
      this.id = detail.id;
      this.salesId = detail.salesId;
      this.itemId = detail.itemId;
      this.sizeId = detail.sizeId;
      this.disc = detail.disc;
      this.qty = detail.qty;
      this.price = detail.price;
    }
  }
}

export class SalesPaymentValues {
  id?: string = undefined;
  salesId?: string = undefined;
  paymentTypeId: string = "";
  needAccountNo = false;
  bankAccountId?: string = undefined;
  date: Date = new Date();
  amount?: number = 0;
  inputDate?: Date = new Date();

  constructor(payment?: SalesPaymentValues) {
    if (payment) {
      this.id = payment.id;
      this.salesId = payment.salesId;
      this.paymentTypeId = payment.paymentTypeId;
      this.needAccountNo = payment.needAccountNo;
      this.bankAccountId = payment.bankAccountId;
      this.date = payment.date;
      this.amount = payment.amount;
      this.inputDate = payment.inputDate;
    }
  }
}

export class SalesFormValues {
  id?: string = undefined;
  date: Date = new Date();
  customerName: string = "";
  storeId: string = "";
  tax?: number = undefined;
  disc?: number = undefined;
  voucher: string = "";
  details: SalesDetailValues[] = [];
  payments: SalesPaymentValues[] = [];

  constructor(sales?: SalesFormValues, result?: Sale, storeId?: string) {
    if (sales) {
      this.id = sales.id;
      this.date = new Date(sales.date);
      this.customerName = sales.customerName;
      this.storeId = sales.storeId;
      this.tax = sales.tax;
      this.disc = sales.disc;
      this.voucher = sales.voucher;
      this.details = sales.details;
      this.payments = sales.payments;
    }
    if (result) {
      this.id = result.id;
      this.date = new Date(result.date);
      this.customerName = result.customerName;
      this.storeId = result.store.id;
      this.tax = result.tax;
      this.disc = result.disc;
      this.voucher = result.voucher;
      this.details = result.details.map(
        (detail) =>
          new SalesDetailValues({
            id: detail.id,
            salesId: detail.salesId,
            itemId: detail.itemId,
            sizeId: detail.size.id,
            disc: detail.disc,
            qty: detail.qty,
            price: detail.price,
          })
      );
      this.payments = result.payments.map(
        (payment) =>
          new SalesPaymentValues({
            id: payment.id,
            salesId: payment.salesId,
            paymentTypeId: payment.paymentType.id,
            needAccountNo: payment.paymentType.needAccountNo,
            bankAccountId: payment.paymentType.needAccountNo
              ? payment.bankAccount?.id
              : undefined,
            date: new Date(payment.date),
            amount: payment.amount,
            inputDate: payment.inputDate,
          })
      );
    }
    if (storeId) {
      this.storeId = storeId;
    }
  }
}
