import { createContext, useContext } from "react";
import CashFlowStore from "./cashFlowStore";
import CommonStore from "./commonStore";
import DashboardStore from "./dashboardStore";
import DialogStore from "./dialogStore";
import InventoriesStore from "./inventoriesStore";
import ItemStore from "./itemStore";
import LocationAccessRightStore from "./locationAccessRightStore";
import ManageMenuStore from "./manageStore";
import MenuStore from "./menuStore";
import SalesStore from "./salesStore";
import SnackbarStore from "./snackbarStore";
import StockControlStore from "./stockControlStore";
import StockLocationStore from "./stockLocationStore";
import StoreStore from "./storeStore";
import TableStore from "./tableStore";
import TransferListStore from "./transferListStore";
import UserStore from "./userStore";

interface Store {
  menuStore: MenuStore;
  tableStore: TableStore;
  transferListStore: TransferListStore;
  snackbarStore: SnackbarStore;
  dialogStore: DialogStore;
  userStore: UserStore;
  commonStore: CommonStore;
  stockLocationStore: StockLocationStore;
  storeStore: StoreStore;
  manageMenuStore: ManageMenuStore;
  itemStore: ItemStore;
  locationAccessRightStore: LocationAccessRightStore;
  stockControlStore: StockControlStore;
  salesStore: SalesStore;
  cashFlowStore: CashFlowStore;
  dashboardStore: DashboardStore;
  inventoriesStore: InventoriesStore;
}

export const store: Store = {
  menuStore: new MenuStore(),
  tableStore: new TableStore(),
  transferListStore: new TransferListStore(),
  snackbarStore: new SnackbarStore(),
  dialogStore: new DialogStore(),
  userStore: new UserStore(),
  commonStore: new CommonStore(),
  stockLocationStore: new StockLocationStore(),
  storeStore: new StoreStore(),
  manageMenuStore: new ManageMenuStore(),
  itemStore: new ItemStore(),
  locationAccessRightStore: new LocationAccessRightStore(),
  stockControlStore: new StockControlStore(),
  salesStore: new SalesStore(),
  cashFlowStore: new CashFlowStore(),
  dashboardStore: new DashboardStore(),
  inventoriesStore: new InventoriesStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
