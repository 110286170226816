import { Avatar } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import {
  StyledTableRow,
  StyledTableCell,
} from "../../app/common/table/EnhancedTableHead";
import { StockBalance } from "../../app/models/inventories";
import { Size } from "../../app/models/item";

interface Props {
  row: StockBalance;
  dynamicRow: Size[];
}

export default observer(function ReportStockBalanceTableRow(props: Props) {
  const { row, dynamicRow } = props;
  const totalQty = row.sizes.reduce(
    (total, currentData) => (total = total + currentData.qty),
    0
  );

  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row" align="center">
        <Avatar
          alt={row.itemCode}
          src={row.image ? row.image : "/no-image.png"}
        />
      </StyledTableCell>
      <StyledTableCell>{row.itemCategory}</StyledTableCell>
      <StyledTableCell>{row.itemCode}</StyledTableCell>
      <StyledTableCell>{row.itemDescription}</StyledTableCell>
      <StyledTableCell>{row.color}</StyledTableCell>
      {dynamicRow.map((size, index) => {
        const sizes = row.sizes.find((x) => x.id === size.id);
        const total = sizes?.qty;

        if (total !== undefined) {
          if (total < 0)
            return (
              <StyledTableCell
                style={{ color: "red" }}
                key={index}
                align="center"
              >
                {total.toLocaleString()}
              </StyledTableCell>
            );
          else
            return (
              <StyledTableCell key={index} align="center">
                {total.toLocaleString()}
              </StyledTableCell>
            );
        } else
          return (
            <StyledTableCell key={index} align="center">
              0
            </StyledTableCell>
          );
      })}
      <StyledTableCell align="center">
        {totalQty.toLocaleString()}
      </StyledTableCell>
    </StyledTableRow>
  );
});
