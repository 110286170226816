import {
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import InputField from "../../app/common/form/InputField";
import MySwitch from "../../app/common/form/MySwitch";
import Copyright from "../../app/layout/Copyright";
import { useStyles } from "../../app/layout/style";
import { LoginFormValues } from "../../app/models/user";
import { useStore } from "../../app/stores/store";

const useStyle = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(12, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    width: 50,
    marginRight: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function LoginForm() {
  const classes = useStyle();
  const classy = useStyles();
  const { userStore, snackbarStore } = useStore();

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img src="/kedjora.png" alt="logo" className={classes.avatar} />
            </Grid>
            <Grid item>
              <Typography component="h1" variant="h5">
                KEDJORA BOUTIQUE
              </Typography>
            </Grid>
          </Grid>
          <Formik
            initialValues={new LoginFormValues()}
            onSubmit={(values) =>
              userStore
                .login(values)
                .catch((error) => snackbarStore.openSnackbar(error, "error"))
            }
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form className={classes.form} onSubmit={handleSubmit}>
                <InputField
                  required
                  variant="outlined"
                  name="userName"
                  label="Username"
                  autoFocus
                />
                <InputField
                  required
                  variant="outlined"
                  label="Password"
                  name="password"
                  type="password"
                />
                <MySwitch name="rememberMe" label="Remember me" />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <CircularProgress
                      className={classy.progress}
                      size={16}
                      color="inherit"
                    />
                  )}
                  Sign In
                </Button>
              </Form>
            )}
          </Formik>
          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
