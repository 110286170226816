import { Documents } from "./document";
import { Store } from "./location";
import { BankAccount } from "./sale";

export interface CashFlowType {
  id: string;
  description: string;
}

export class CashFlowListSearch {
  startDate: Date = new Date();
  endDate: Date = new Date();
  bankAccountId: string = "";

  constructor(search: CashFlowListSearch) {
    if (search) {
      this.startDate = search.startDate;
      this.endDate = search.endDate;
      this.bankAccountId = search.bankAccountId;
    }
  }
}

export interface CashFlow extends Documents {
  store: Store;
  cashFlowType: CashFlowType;
  bankAccount: BankAccount;
  description: string;
  amount: number;
  balance: number;
  canChange: boolean;
}

export class CashFlowFormValues {
  id?: string = undefined;
  date: Date = new Date();
  storeId: string = "";
  cashFlowTypeId: string = "";
  bankAccountId: string = "";
  description: string = "";
  amount: number = parseInt("");

  constructor(cashflow?: CashFlowFormValues, result?: CashFlow) {
    if (cashflow) {
      this.id = cashflow.id;
      this.date = new Date(cashflow.date);
      this.storeId = cashflow.storeId;
      this.cashFlowTypeId = cashflow.cashFlowTypeId;
      this.bankAccountId = cashflow.bankAccountId;
      this.description = cashflow.description;
      this.amount = cashflow.amount;
    }
    if (result) {
      this.id = result.id;
      this.date = new Date(result.date);
      this.storeId = result.store.id;
      this.cashFlowTypeId = result.cashFlowType.id;
      this.bankAccountId = result.bankAccount.id;
      this.description = result.description;
      this.amount = result.amount;
    }
  }
}
