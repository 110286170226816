import Select, { SelectProps } from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { useField } from "formik";
import React from "react";
import { DropDownProps } from "../../models/dropDownProps";

interface Props extends SelectProps {
  placeholder?: string;
  name: string;
  options: DropDownProps[];
  onChange?: (e: React.ChangeEvent<{ value: unknown }>) => void;
  displayDefaultSelected?: boolean;
  title?: string;
  displayErrorMessage?: boolean;
  disableDefaultSelected?: boolean;
}

export default function Dropdown(props: Props) {
  const [field, meta, helpers] = useField(props.name);
  const {
    displayDefaultSelected,
    title,
    displayErrorMessage,
    disableDefaultSelected,
    ...other
  } = props;

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    helpers.setValue(e.target.value);
    if (props.onChange !== undefined) {
      props.onChange(e);
    }
  };

  return (
    <FormControl
      color="primary"
      variant="standard"
      fullWidth
      error={meta.touched && !!meta.error}
      margin={props.margin ? props.margin : "normal"}
    >
      <InputLabel shrink id={`${props.name}-label`}>
        {props.placeholder}
      </InputLabel>
      <Select
        {...other}
        id={props.name}
        labelId={`${props.name}-label`}
        value={field.value || ""}
        onChange={(e) => handleChange(e)}
        onBlur={() => helpers.setTouched(true)}
        color="primary"
        displayEmpty
      >
        {!displayDefaultSelected && (
          <MenuItem value="" disabled={disableDefaultSelected}>
            {title ? props.title : "Not Selected"}
          </MenuItem>
        )}
        {props.options !== undefined &&
          props.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
      </Select>
      {meta.touched && meta.error && !displayErrorMessage ? (
        <FormHelperText>{meta.error}</FormHelperText>
      ) : null}
    </FormControl>
  );
}
